<template>
  <el-dialog
    :title="settingCode.title"
    :visible.sync="settingCode.show"
    class="DialogTD"
    width="36%"
    append-to-body
    :before-close="handleClose">
    <el-transfer
      style="text-align: left; display: inline-block"
      v-model="showValues"
      target-order="push"
      filterable
      :titles="['隐藏内容', '显示内容']"
      :button-texts="['隐藏', '显示']"
      :format="{
        noChecked: '${total}',
        hasChecked: '${checked}/${total}'
      }"
      :props="{
        key: 'id',
        label: 'label'
      }"
      @change="handleChange"
      @right-check-change="righthandleChange"
      :data="DataTransfer">
      <el-button class="transfer-footer" slot="right-footer" size="small" type="primary" icon="el-icon-arrow-down" circle @click="show_down"></el-button>
      <el-button class="transfer-footer" slot="right-footer" size="small" type="primary" icon="el-icon-arrow-up" circle @click="show_up"></el-button>
    </el-transfer>
    <el-checkbox :value="settingCode.isSetting" class="ckeckSystem" @change="handleCheckedCitiesChange">{{settingCode.sysCheck}}</el-checkbox>
    <span slot="footer" class="dialog-footer">
      <el-button @click="settingCode.show = false">取 消</el-button>
      <el-button type="primary" @click="SettingOnClick">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { addTableUser } from '@/api/sys.ui'

export default {
  name: 'd2-container-query-setting',
  props: {
    settingCode: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      DataTransfer: [],
      showValues: [],
      checkValues: [],
      displayId: ''
    }
  },
  methods: {
    handleClose (done) {
      this.settingCode.show = false
      done()
    },
    handleChange (value, direction, movedKeys) {
    },
    righthandleChange (direction, movedKeys) {
      this.checkValues = direction
    },
    show_down () {
      if (this.checkValues.length < this.showValues.length && this.checkValues) {
        this.checkValues.forEach((itemc, _check) => {
          // 因ESLint中break 等跳出for标识不法使用,所以用声明变量标识方法处理
          let _showMark = 0
          this.showValues.forEach((items, _show) => {
            if (_showMark === 0 && itemc === items && _show < this.showValues.length) {
              this.showValues[_show] = this.showValues[_show + 1]
              this.showValues[_show + 1] = itemc
              _showMark = 1
            }
          })
        })
      }
      this.settingCode.show = false
      this.settingCode.show = true
    },
    show_up () {
      if (this.checkValues.length < this.showValues.length && this.checkValues) {
        this.checkValues.forEach((itemc, _check) => {
          // 因ESLint中break 等跳出for标识不法使用,所以用声明变量标识方法处理
          let _showMark = 0
          this.showValues.forEach((items, _show) => {
            if (_showMark === 0 && itemc === items && _show > 0) {
              this.showValues[_show] = this.showValues[_show - 1]
              this.showValues[_show - 1] = itemc
              _showMark = 1
            }
          })
        })
      }
      this.settingCode.show = false
      this.settingCode.show = true
    },
    changSetting () {
      this.displayId = this.settingCode.DataTransfer[0].dispalyId
      for (let index in this.settingCode.DataTransfer) {
        if (this.settingCode.DataTransfer[index].tableUserDefine === '0') {
          this.settingCode.DataTransfer[index].index = parseInt(index)
          if (this.settingCode.isSetting && this.settingCode.DataTransfer[index].isShowTable === '0') {
            this.showValues.push(this.settingCode.DataTransfer[index].id)
          }
          this.settingCode.DataTransfer[index].showValue = false
          this.DataTransfer.push(this.settingCode.DataTransfer[index])
        }
      }
    },
    handleCheckedCitiesChange (value) {
      this.settingCode.isSetting = value
      for (let index in this.settingCode.DataTransfer) {
        this.settingCode.DataTransfer[index].disabled = !this.settingCode.isSetting
      }
      if (!value) {
        this.showValues = []
      }
    },
    SettingOnClick () {
      if (this.settingCode.isSetting && this.showValues.length === 0) {
        this.$message.error('启用自定义查询时,显示内容必须要有条件信息')
      } else {
        let obj = { showList: this.showValues, displayId: this.displayId }
        addTableUser(obj).then(response => {
          // 成功时
          this.$emit('onSave', this.settingCode)
        })
      }
    }
  },
  created () {
    this.changSetting()
  }
}
</script>

<style lang="scss">
.ckeckSystem{
  margin-top: 20px
}
.DialogTD .el-dialog__header{
  //background-color: #409EFF;
  border-bottom: 1px solid #F0F0F0;
}
.DialogTD .el-dialog__title{
  //color: #fff;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 600;
  color: #343434;
  //line-height: 36px;
  letter-spacing: 1px;
}
.DialogTD .el-dialog__headerbtn .el-dialog__close {
  color: #D9D9D9;
}
.DialogTD .el-dialog__headerbtn .el-dialog__close:hover {
  color: #ff1ec8;
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-table-setting/index.vue"
}
</vue-filename-injector>
