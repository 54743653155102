<template>
  <div
    class="d2-layout-header-aside-group"
    :style="styleLayoutMainGroup"
    :class="{grayMode: grayActive}">
    <!-- 半透明遮罩 -->
    <div class="d2-layout-header-aside-mask"></div>
    <!-- 主体内容 -->
    <div class="d2-layout-header-aside-content" flex="dir:top">
      <!-- 顶栏 -->
      <div
        class="d2-theme-header"
        :style="{
          opacity: this.searchActive ? 0.5 : 1
        }"
        flex-box="0"
        flex>
        <div class="logo-group" :style="{width: asideCollapse ? asideWidthCollapse : asideWidth}" flex-box="0">
          <img v-if="asideCollapse" :src="`${$baseUrl}image/theme/${themeActiveSetting.name}/logo/icon-only.png`">
          <img v-else :src="`${$baseUrl}image/theme/${themeActiveSetting.name}/logo/all.png`">
        </div>
        <div class="toggle-aside-btn" @click="handleToggleAside" flex-box="0">
          <d2-icon name="bars"/>
        </div>
        <div class="d2-header-left" flex-box="0">
          <d2-header-locales style="display: none"/>
<!--          <d2-header-user/>-->
          <d2-header-fullscreen/>
          <d2-header-theme/>
          <d2-header-size/>
          <d2-header-log v-if="$env === 'development'"/>
          <d2-header-export-records></d2-header-export-records>
<!--          <d2-header-document-route></d2-header-document-route>-->
        </div>
        <d2-menu-header flex-box="1"/>
        <!-- 顶栏右侧 -->
        <div class="d2-header-right" flex-box="0">
          <!-- 如果你只想在开发环境显示这个按钮请添加 v-if="$env === 'development'" -->
<!--          <d2-header-search @click="handleSearchClick"/>-->
<!--          <d2-header-export-records></d2-header-export-records>-->
<!--          <d2-header-log v-if="$env === 'development'"/>-->
<!--          <d2-header-fullscreen/>-->
<!--          <d2-header-theme/>-->
<!--          <d2-header-size/>-->
          <!-- 先隐藏了，没时间搞了，看计划进行安排吧台 -->
<!--          <d2-header-locales style="display: none"/>-->
          <d2-header-user/>
<!--          <d2-header-color/>-->
        </div>
      </div>
      <!-- 下面 主体 -->
      <div class="d2-theme-container" flex-box="1" flex>
        <!-- 主体 侧边栏 -->
        <div
          flex-box="0"
          ref="aside"
          class="d2-theme-container-aside"
          :style="{
            width: asideCollapse ? asideWidthCollapse : asideWidth,
            opacity: this.searchActive ? 0.5 : 1
          }">
          <d2-menu-side/>
        </div>
        <!-- 主体 -->
        <div class="d2-theme-container-main" flex-box="1" flex>
          <!-- 搜索 -->
          <transition name="fade-scale">
            <div v-if="searchActive" class="d2-theme-container-main-layer" flex>
              <d2-panel-search
                ref="panelSearch"
                @close="searchPanelClose"/>
            </div>
          </transition>
          <!-- 内容 -->
          <transition name="fade-scale">
            <div v-if="!searchActive" class="d2-theme-container-main-layer" flex="dir:top">
              <!-- tab -->
              <div class="d2-theme-container-main-header" flex-box="0">
                <d2-tabs/>
              </div>
              <!-- 页面 -->
              <div class="d2-theme-container-main-body" flex-box="1">
                <transition :name="transitionActive ? 'fade-transverse' : ''">
                  <keep-alive :include="keepAlive">
                    <router-view/>
                  </keep-alive>
                </transition>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import d2MenuSide from './components/menu-side'
import d2MenuHeader from './components/menu-header'
import d2Tabs from './components/tabs'
import d2HeaderFullscreen from './components/header-fullscreen'
import d2HeaderLocales from './components/header-locales'
// import d2HeaderSearch from './components/header-search'
import d2HeaderSize from './components/header-size'
import d2HeaderTheme from './components/header-theme'
import d2HeaderUser from './components/header-user'
import d2HeaderLog from './components/header-log'
// import d2HeaderColor from './components/header-color'
import d2HeaderExportRecords from './components/header-export-records'
import { mapState, mapGetters, mapActions } from 'vuex'
import mixinSearch from './mixins/search'
import { validatenull } from '@/tdcommon/validate'

export default {
  name: 'd2-layout-header-aside',
  mixins: [
    mixinSearch
  ],
  components: {
    d2MenuSide,
    d2MenuHeader,
    d2Tabs,
    d2HeaderFullscreen,
    d2HeaderLocales,
    // d2HeaderSearch,
    d2HeaderSize,
    d2HeaderTheme,
    d2HeaderUser,
    d2HeaderLog,
    // d2HeaderColor
    d2HeaderExportRecords
  },
  data () {
    return {
      // [侧边栏宽度] 正常状态
      asideWidth: '140px',
      // [侧边栏宽度] 折叠状态
      asideWidthCollapse: '65px',
      // 刷新token锁
      refreshLock: false,
      // 刷新token的时间
      refreshTime: '',
      // 计时器
      timer: ''
    }
  },
  created () {
    // 实时检测刷新token
    this.refreshToken()
  },
  destroyed () {
    clearInterval(this.refreshTime)
    clearInterval(this.timer)
    /*
    * 默认关闭websocket，如需工作流通知，则开启
    * this.disconnect()
    */
  },
  computed: {
    ...mapState('d2admin', {
      keepAlive: state => state.page.keepAlive,
      grayActive: state => state.gray.active,
      transitionActive: state => state.transition.active,
      asideCollapse: state => state.menu.asideCollapse
    }),
    ...mapGetters('d2admin', {
      themeActiveSetting: 'theme/activeSetting'
    }),
    ...mapGetters(['expires_in', 'access_token']),
    /**
     * @description 最外层容器的背景图片样式
     */
    styleLayoutMainGroup () {
      return {
        ...this.themeActiveSetting.backgroundImage ? {
          backgroundImage: `url('${this.$baseUrl}${this.themeActiveSetting.backgroundImage}')`
        } : {}
      }
    }
  },
  methods: {
    ...mapActions('d2admin/menu', [
      'asideCollapseToggle'
    ]),
    /**
     * 接收点击切换侧边栏的按钮
     */
    handleToggleAside () {
      this.asideCollapseToggle()
    },
    // 实时检测刷新token
    refreshToken () {
      this.refreshTime = setInterval(() => {
        const token = this.access_token
        if (validatenull(token)) {
          return
        }
        if (this.expires_in <= 1000 && !this.refreshLock) {
          this.refreshLock = true
          this.$store
            .dispatch('d2admin/account/RefreshToken')
            .catch(() => {
              clearInterval(this.refreshTime)
            })
          this.refreshLock = false
        }
        this.$store.commit('d2admin/account/SET_EXPIRES_IN', this.expires_in - 10)
      }, 10000)
    }
  }
}
</script>

<style lang="scss">
  // 注册主题
  @import '~@/assets/style/theme/register.scss';
  .d2-theme-container-aside {
    padding: 0;
  }
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/layout.vue"
}
</vue-filename-injector>
