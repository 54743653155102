import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/tms/hd/page',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/tms/tmshd',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/tms/tmshd/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/tms/tmshd/' + id,
    method: 'delete'
  })
}

export function putObj (obj) {
  return request({
    url: '/tms/tmshd',
    method: 'put',
    data: obj
  })
}
/**
 * 通过ids进行 纸质回单审核
 */
export function hdAudit (ids) {
  return request({
    url: '/tms/hd/audit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 纸质回单反审核
 */
export function hdUnaudit (ids) {
  return request({
    url: '/tms/hd/auditundo',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 电子回单审核
 */
export function hdAuditdoc (ids) {
  return request({
    url: '/tms/hd/auditdoc',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 电子回单反审核
 */
export function hdUnauditdoc (ids) {
  return request({
    url: '/tms/hd/auditdocundo',
    method: 'post',
    params: ids
  })
}
/**
 * 回单收单
 */
export function hdReceive (obj) {
  return request({
    url: '/tms/tmshd/receive',
    method: 'post',
    data: obj
  })
}
/**
 * 回单反收单
 */
export function hdReceiveundo (ids) {
  return request({
    url: '/tms/tmshd/receiveundo',
    method: 'post',
    params: ids
  })
}
/**
 * 回单付单
 */
export function hdSend (obj) {
  return request({
    url: '/tms/tmshd/send',
    method: 'post',
    data: obj
  })
}
/**
 * 回单反付单
 */
export function hdSendundo (ids) {
  return request({
    url: '/tms/tmshd/sendundo',
    method: 'post',
    params: ids
  })
}
/**
 * 回单签单
 */
export function hdsign (obj) {
  return request({
    url: '/tms/hd/sign',
    method: 'post',
    data: obj
  })
}
/**
 * 回单反签单
 */
export function hdsignundo (ids) {
  return request({
    url: '/tms/hd/signundo',
    method: 'post',
    params: ids
  })
}
/**
 * 回单返单
 */
export function hdreturn (obj) {
  return request({
    url: '/tms/hd/return',
    method: 'post',
    data: obj
  })
}
/**
 * 回单撤销返单
 */
export function hdreturnundo (ids) {
  return request({
    url: '/tms/hd/returnUndo',
    method: 'post',
    params: ids
  })
}
/**
 * 上传阿里 回单保存到后台
 */
export function uploadAliHd (obj) {
  return request({
    url: '/tms/ossobject/upload',
    method: 'post',
    data: obj
  })
}
/**
 * 批量上传回单保存到后台
 */
export function uploadAliHdBatch (obj) {
  return request({
    url: '/tms/ossobject/batchSaveHd',
    method: 'post',
    data: obj
  })
}
/**
 * 通过ids 批量下载回单
 */
export function bacthHdDownload (ids) {
  return request({
    url: '/tms/ossobject/aliBigBatchDownload',
    method: 'post',
    data: ids
  })
}
export function fetchReturnList (query) {
  return request({
    url: '/tms/hd/returnPage',
    method: 'post',
    data: query
  })
}
export function getHdReport (query) {
  return request({
    url: '/tms/hd/report',
    method: 'post',
    data: query
  })
}
/**
 * 通过派车单号查询回单信息
 */
export function disOrderSearch (disOrderNo) {
  return request({
    url: '/tms/hd/disOrderSearch',
    method: 'post',
    params: { disOrderNo: disOrderNo }
  })
}
