<template>
  <el-select class="td_select"
             v-model="values"
             value-key="value"
             default-first-option
             filterable
             remote
             reserve-keyword
             placeholder="请输入关键词"
             :clearable="clearable"
             :disabled="disabled"
             :filter-method="filterMethod"
             @clear="clearAction"
             :loading="loading">
    <el-option
        class="td_option"
        v-for="(item, index) in options"
        :key="index"
        :label="item.value+ '  ' +item.label"
        :value="{ value: item.value, label: item.label }"
        @click.native="handleSelect(item)">
      <span style="float: left;"><i class="fa fa-gg-circle" :style="{'color':item.description==='收入'?'#f78989':'#66b1ff','margin-right': '5px'}"></i>{{ item.value }}</span>
      <span style="float: left; margin-left: 20px; color: #8492a6; font-size: 13px;">{{ item.label }}</span>
    </el-option>
  </el-select>
</template>
<script>
// 应收账单 收支大类 查询条件 码+名称
import { validatenull } from '@/tdcommon/validate'
import { getdict } from '@/api/admin/dict'

export default {
  name: 'd2-income-search',
  data () {
    return {
      values: {},
      options: [],
      copyOptions: [],
      loading: false,
      mdmStrMark: ''
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    sourcetype: {
      type: String,
      default: () => ''
    },
    mdmstr: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    remoteMethod (query) {
      this.loading = true
      getdict('fee_type_code').then(response => {
        if (response.code === 0) {
          console.log('filter', response.data.filter(item => item.description === '收入'))
          this.loading = false
          this.options = response.data.filter(item => item.description === '收入')
          this.copyOptions = response.data.filter(item => item.description === '收入')
        }
      }).catch(() => {
        this.loading = false
      })
    },
    // 自定义搜索
    async filterMethod (val) {
      this.options = []
      if (!validatenull(val)) {
        this.copyOptions.filter(item => {
          if (String(item.label).includes(val) || String(item.value).includes(val)) {
            this.options.push(item)
          }
        })
      } else {
        this.options = this.copyOptions
      }
    },
    clearAction () {
      this.options = []
      this.options = this.copyOptions
    },
    handleSelect (val) {
      console.log('handleSelect', val)
      this.values = { value: val.value, label: val.label }
      this.$emit('change', val)
    }
  },
  watch: {
    values: function (val, oldVal) {
      // 查询条件值是 val.value
      this.$emit('input', validatenull(val) ? '' : val.value)
    },
    value: function (val, oldVal) {
      if (!validatenull(this.options) && val !== oldVal) {
        this.remoteMethod(val)
      }
    }
  },
  mounted () {
    this.remoteMethod(this.value)
  }
}
</script>

<style lang="scss" scoped>
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-income-search/index.vue"
}
</vue-filename-injector>
