import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/tms/haiersupplier/page',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/tms/haiersupplier',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/tms/haiersupplier/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/tms/haiersupplier/' + id,
    method: 'delete'
  })
}

export function putObj (obj) {
  return request({
    url: '/tms/haiersupplier',
    method: 'put',
    data: obj
  })
}

export function pageSearch (query) {
  return request({
    url: '/tms/haiersupplier/pageSearch',
    method: 'get',
    params: query
  })
}
/**
 * 供应商引入 批量删除
 */
export function delObjs (ids) {
  return request({
    url: '/tms/haiersupplier/delbyids',
    method: 'post',
    params: ids
  })
}
export function getMdmCode (obj) {
  return request({
    url: '/tms/haiersupplier/getmdmcode/' + obj,
    method: 'get'
  })
}
/**
 * 启用
 */
export function lockenableObjs (ids) {
  return request({
    url: '/tms/haiersupplier/lockEnable',
    method: 'post',
    params: ids
  })
}
/**
 * 停用
 */
export function lockstopObjs (ids) {
  return request({
    url: '/tms/haiersupplier/lockStop',
    method: 'post',
    params: ids
  })
}
