import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/basic/hremployee/page',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/basic/hremployee',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/basic/hremployee/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/basic/hremployee/' + id,
    method: 'delete'
  })
}

export function putObj (obj) {
  return request({
    url: '/basic/hremployee',
    method: 'put',
    data: obj
  })
}
/*
*用户名 判断重复 *
*/
export function getDetails (obj) {
  return request({
    url: '/basic/hremployee/details/' + obj,
    method: 'get'
  })
}
/*
*用户管理  获取员工档案的用户名*
*/
export function fetchUserId () {
  return request({
    url: '/basic/hremployee/list',
    method: 'get'
  })
}
