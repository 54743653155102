import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/basic/logistic/page',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/basic/logistic',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/basic/logistic/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/basic/logistic/' + id,
    method: 'delete'
  })
}

export function putObj (obj) {
  return request({
    url: '/basic/logistic',
    method: 'put',
    data: obj
  })
}
/**
 * 承运单位 批量删除
 */
export function delObjs (ids) {
  return request({
    url: '/basic/logistic',
    method: 'delete',
    params: ids
  })
}
/**
 * 承运单位 启用
 */
export function lockenableObjs (ids) {
  return request({
    url: '/basic/logistic/lock/open',
    method: 'post',
    params: ids
  })
}
/**
 * 承运单位 停用
 */
export function lockstopObjs (ids) {
  return request({
    url: '/basic/logistic/lock/stop',
    method: 'post',
    params: ids
  })
}
/**
 * 承运单位 判断是否重复
 */
export function getDetails (obj) {
  return request({
    url: '/basic/logistic/details/' + obj,
    method: 'get'
  })
}
/**
 * 通过搜索 得到承运单位名称
 */
export function getLogisticName (search, logisticType) {
  return request({
    url: '/basic/logistic/buslogistic',
    method: 'get',
    params: { searchName: search, logisticType: logisticType }
  })
}
/**
 * 通过搜索 得到承运单位名称
 */
export function getLogistic (search) {
  return request({
    url: '/basic/logistic/getlogistic',
    method: 'get',
    params: { searchName: search }
  })
}
/**
 * 运营中心通过搜索 得到承运单位名称(停用的过滤不显示查询)
 */
export function buslogistic (obj) {
  return request({
    url: '/basic/logistic/buslogistic',
    method: 'get',
    params: obj
  })
}
/**
 * 上传阿里 供应商资料保存到后台
 */
export function uploadAliLogistic (obj) {
  return request({
    url: '/tms/ossobject/logistic',
    method: 'post',
    data: obj
  })
}
/**
 * 营运单导入 返回结果
 */
export function returnImportPrice () {
  return request({
    url: '/basic/logistic/import/progress',
    method: 'get'
  })
}
/**
 * 通过ids进行 财务审核
 */
export function supRetrial (ids) {
  return request({
    url: '/basic/logistic/retrial',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 财务反审核
 */
export function supUnRetrial (ids) {
  return request({
    url: '/basic/logistic/unretrial',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行结算审核
 */
export function supSettlement (ids) {
  return request({
    url: '/basic/logistic/auditSettlement',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 结算反审核
 */
export function supUnSettlement (ids) {
  return request({
    url: '/basic/logistic/unAuditSettlement',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行运力审核
 */
export function supOperate (ids) {
  return request({
    url: '/basic/logistic/operate',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 运力反审核
 */
export function supUnOperate (ids) {
  return request({
    url: '/basic/logistic/unoperate',
    method: 'post',
    params: ids
  })
}
/**
 * 承运单位只修改基础资料信息(不需要未审核、未审单状态，保留启用状态)
 */
export function updateBasicData (obj) {
  return request({
    url: '/basic/logistic/updateBasicData',
    method: 'post',
    data: obj
  })
}
/**
 * 通过ids进行 总经理审核
 */
export function supManager (ids) {
  return request({
    url: '/basic/logistic/managerExamine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 总经理反审核
 */
export function supUnManager (ids) {
  return request({
    url: '/basic/logistic/managerApproval',
    method: 'post',
    params: ids
  })
}
