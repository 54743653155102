import request from '@/plugin/axios'
// 报价单主表分页查询
export function fetchList (query) {
  return request({
    url: '/tms/tmsquotation/page',
    method: 'post',
    data: query
  })
}
// 新增报价单保存
export function addObj (obj) {
  return request({
    url: '/tms/tmsquotation/insertQuotation',
    method: 'post',
    data: obj
  })
}
// 通过id删除报价单主表
export function delObj (id) {
  return request({
    url: '/tms/tmsquotation/' + id,
    method: 'delete'
  })
}
/**
 * 通过ids批量删除运单
 */
export function batchDelObjs (ids) {
  return request({
    url: '/tms/tmsquotation',
    method: 'post',
    params: ids
  })
}
// 修改报价单保存
export function putObj (obj) {
  return request({
    url: '/tms/tmsquotation/updateQuotation',
    method: 'put',
    data: obj
  })
}

/*
* 通过id查询*
*/
export function tredit (id) {
  return request({
    url: '/tms/tmsquotation/queryMessage/' + id,
    method: 'get'
  })
}
// 导入报价单明细表
export function importQuotation (file, type) {
  return request({
    url: '/tms/tmsquotation/importQuotationDetails',
    method: 'post',
    params: { file: file, type: type }
  })
}

/**
 * 通过ids进行 市场审核
 */
export function trAudit (ids) {
  return request({
    url: '/tms/tmsquotation/audit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 市场反审核
 */
export function trUnaudit (ids) {
  return request({
    url: '/tms/tmsquotation/unaudit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 采购审核
 */
export function trExamine (ids) {
  return request({
    url: '/tms/tmsquotation/examine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 采购反审核
 */
export function trUnexamine (ids) {
  return request({
    url: '/tms/tmsquotation/unexamine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 运营审核
 */
export function trRetrial (ids) {
  return request({
    url: '/tms/tmsquotation/retrial',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 运营反审核
 */
export function trUnretrial (ids) {
  return request({
    url: '/tms/tmsquotation/unretrial',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 人力审核
 */
export function trFntrial (ids) {
  return request({
    url: '/tms/tmsquotation/sfkManager',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 人力反审核
 */
export function trUnFntrial (ids) {
  return request({
    url: '/tms/tmsquotation/sfkUnManager',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行财务审核
 */
export function cusFinance (ids) {
  return request({
    url: '/tms/tmsquotation/auditFinance',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行财务反审核
 */
export function cusUnFinance (ids) {
  return request({
    url: '/tms/tmsquotation/unAuditFinance',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行副总审核
 */
export function cusPresident (ids) {
  return request({
    url: '/tms/tmsquotation/auditPresident',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行副总反审核
 */
export function cusUnPresident (ids) {
  return request({
    url: '/tms/tmsquotation/unAuditPresident',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行总经理审核
 */
export function cusManager (ids) {
  return request({
    url: '/tms/tmsquotation/auditManager',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行总经理反审核
 */
export function cusUnManager (ids) {
  return request({
    url: '/tms/tmsquotation/unAuditManager',
    method: 'post',
    params: ids
  })
}
/**
 * 二级表导出*
 */
export function secondFloorExport (ids) {
  return request({
    url: '/tms/tmsquotation/exportSecondaryTable',
    method: 'post',
    responseType: 'blob',
    params: ids
  })
}
/**
 * 获取导入返回值
 */
export function getImportData (key) {
  return request({
    url: '/tms/tmsquotation/revertImportQuotationDetails',
    method: 'post',
    params: key
  })
}
/**
 * 打印 报价单
 */
export function printQuatation (ids) {
  return request({
    url: '/tms/tmsquotation/quotationPrintingInformation',
    method: 'post',
    responseType: 'blob',
    params: ids
  })
}
/*
* 跳转立项报告*
*/
export function toIntroduce (id) {
  return request({
    url: '/tms//tmsquotation/queryIntroduceId/' + id,
    method: 'get'
  })
}
/**
 * 上传阿里 调研报告
 */
export function uploadAliResearch (obj) {
  return request({
    url: '/tms/ossobject/investigationReport',
    method: 'post',
    data: obj
  })
}
/**
 * word生成PDF在线查看
 */
export function wordToPDF (obj) {
  return request({
    url: '/tms/ossobject/generateOnLinePreview',
    method: 'post',
    params: obj
  })
}
