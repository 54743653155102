import request from '@/plugin/axios'

/**
 * 获取树形数据
 */
export function fetchtree (query) {
  return request({
    url: '/tms/tmsGoods/queryAllData',
    method: 'get',
    data: query
  })
}
export function addObj (obj) {
  return request({
    url: '/tms/tmsGoods/insertTmsGoods',
    method: 'post',
    data: obj
  })
}

export function delObj (id) {
  return request({
    url: '/tms/tmsGoods/batchDelete/' + id,
    method: 'delete'
  })
}

export function putObj (obj) {
  return request({
    url: '/tms/tmsGoods/updateTmsGoods',
    method: 'post',
    data: obj
  })
}
/*
*物品类别 判断重复 *
*/
export function getRepeatDetails (obj) {
  return request({
    url: '/tms/tmsGoods/queryRepeatData/' + obj,
    method: 'get'
  })
}
/*
*物品名称 判断重复 *
*/
export function getRepeatMetering (obj) {
  return request({
    url: '/tms/tmsGoods/queryRepeatItemCategory',
    method: 'get',
    params: obj
  })
}
/**
 * 物品名称中获取计量组的数据
 */
export function getMeasureData (query) {
  return request({
    url: '/tms/tmsItemSetup/queryGoodsMeteringData',
    method: 'get',
    data: query
  })
}
/**
 * 其他模块 获取物品名称
 */
export function getItemsName () {
  return request({
    url: '/tms/tmsGoods/queryItemNameData',
    method: 'get'
  })
}
