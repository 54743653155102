<template class="pricingType_style">
  <el-row>
    <el-col :span="14">
      <el-select class="td_select"
                 v-model="value"
                 placeholder="请选择"
                 :clearable="clearable"
                 :disabled="disabled">
        <el-option
            class="td_option"
            v-for="(item) in opList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            @click.native="handleClick('select')">
        </el-option>
      </el-select>
    </el-col>
    <el-col :span="6">
      <el-button size="mini" type="primary" style="padding: 5px 12px;border-radius: 4px;" :disabled="disabled" @click="handleClick('button')">自动计费</el-button>
    </el-col>
  </el-row>
</template>
<script>

export default {
  name: 'd2-container-priceType',
  data () {
    return {
      opList: this.options
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    rules: {
      type: Object,
      default: () => {}
    },
    options: {
      type: Array,
      default: () => {
        return [ // 1-件数，2-计费重量，3-计费体积，4-车型，5-车公里
          { value: '4', label: '车型' },
          { value: '5', label: '车公里' }
        ]
      }
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    handleClick (type) {
      this.$emit('change', type)
    },
    changeOption (val) {
      if (val === '1') {
        this.opList = [ { value: '4', label: '车型' }, { value: '5', label: '车公里' } ]
      } else if (val === '2' || val === '3') {
        this.opList = [ { value: '1', label: '件数' }, { value: '2', label: '计费重量' }, { value: '3', label: '计费体积' } ]
      } else if (val === '4') {
        this.opList = [ { value: '4', label: '车型' } ]
      }
    }
  },
  watch: {
    value: function (val, oldVal) {
      this.$emit('input', val)
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.pricingType_style {
  .el-form-item__error {
    left: 40% !important;
  }
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-pricingType/index.vue"
}
</vue-filename-injector>
