import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/tms/tmssh/page',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/tms/tmssh',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/tms/tmssh/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/tms/tmssh/' + id,
    method: 'delete'
  })
}

export function putObj (obj) {
  return request({
    url: '/tms/tmssh',
    method: 'put',
    data: obj
  })
}
/**
 * 新增送货单保存
 */
export function shdsavaZZ (obj) {
  return request({
    url: '/tms/tmssh/savesh',
    method: 'post',
    data: obj
  })
}
/**
 * 送货单更新接口
 */
export function shupdateZZ (obj) {
  return request({
    url: '/tms/tmssh/updatesh',
    method: 'post',
    data: obj
  })
}
/*
* 通过id查询*
*/
export function shdedit (id) {
  return request({
    url: '/tms/tmssh/shedit/' + id,
    method: 'get'
  })
}
/**
 * 通过ids批量删除运单
 */
export function shdelObjs (ids) {
  return request({
    url: '/tms/tmssh/delshids',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行送货单审单
 */
export function shAudit (ids) {
  return request({
    url: '/tms/tmssh/audit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行送货单反审单
 */
export function shUnaudit (ids) {
  return request({
    url: '/tms/tmssh/unaudit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行送货单审核
 */
export function shExamine (ids) {
  return request({
    url: '/tms/tmssh/examine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行送货单反审核
 */
export function shUnexamine (ids) {
  return request({
    url: '/tms/tmssh/unexamine',
    method: 'post',
    params: ids
  })
}
/*
* 中转单详情接口*
*/
export function getShDetails (id) {
  return request({
    url: '/tms/tmssh/shdetails/' + id,
    method: 'get'
  })
}
/*
* 通过托运单ydNo查询*
*/
export function getShListByTydId (ydNo) {
  return request({
    url: '/tms/tmssh/yddetails',
    method: 'get',
    params: ydNo
  })
}
/*
* 上传阿里*
*/
export function uploadAliSh (obj) {
  return request({
    url: '/tms/ossobject/delivery',
    method: 'post',
    data: obj
  })
}
/**
 * 通过ids进行送货单复审
 */
export function shRetial (ids) {
  return request({
    url: '/tms/tmssh/retrial',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行送货单反复审
 */
export function shUnretrial (ids) {
  return request({
    url: '/tms/tmssh/unretrial',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids生成费用异动
 */
export function generateTransaction (ids) {
  return request({
    url: '/tms/tmssh/shBatchGenerateChange',
    method: 'post',
    params: ids
  })
}
