import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/tms/tmsdd/page',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/tms/tmsdd',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/tms/tmsdd/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/tms/tmsdd/' + id,
    method: 'delete'
  })
}

export function putObj (obj) {
  return request({
    url: '/tms/tmsdd',
    method: 'put',
    data: obj
  })
}
/**
 * 保存
 */
export function savaDD (obj) {
  return request({
    url: '/tms/tmsdd/savedd',
    method: 'post',
    data: obj
  })
}
/**
 * 修改 明细表获取数据
 */
export function getData (id) {
  return request({
    url: '/tms/tmsdd/ordersearch/' + id,
    method: 'get'
  })
}
/**
 * 修改预订单主表
 */
export function updateDD (obj) {
  return request({
    url: '/tms/tmsdd/updatedd',
    method: 'post',
    data: obj
  })
}

/**
 * 预订单 批量删除
 */
export function delObjs (ids) {
  return request({
    url: '/tms/tmsdd/delbyids',
    method: 'post',
    params: ids
  })
}
/**
 * 预订单 批量审核
 */
export function examineObjs (ids) {
  return request({
    url: '/tms/tmsdd/examine',
    method: 'post',
    params: ids
  })
}
/**
 * 预订单 批量反审核
 */
export function unexamineObjs (ids) {
  return request({
    url: '/tms/tmsdd/unexamine',
    method: 'post',
    params: ids
  })
}
/**
 * 预订单 批量审单
 */
export function auditObjs (ids) {
  return request({
    url: '/tms/tmsdd/audit',
    method: 'post',
    params: ids
  })
}
/**
 * 预订单 批量反审单
 */
export function unauditObjs (ids) {
  return request({
    url: '/tms/tmsdd/unaudit',
    method: 'post',
    params: ids
  })
}

/**
 * 预订单 货源选择
 */
export function hyoutpage (query) {
  return request({
    url: '/tms/tmsdd/hyoutpage',
    method: 'post',
    data: query
  })
}

/**
 * 预订单 运单选择
 */
export function ydoutpage (query) {
  return request({
    url: '/tms/tmsdd/ydoutpage',
    method: 'post',
    data: query
  })
}

/**
 * 货源 预出库信息
 */
export function hyoutcheck (query) {
  return request({
    url: '/tms/tmsdd/hyoutcheck',
    method: 'post',
    params: query
  })
}

/**
 * 运单 预出库信息
 */
export function ydoutcheck (query) {
  return request({
    url: '/tms/tmsdd/ydoutcheck',
    method: 'post',
    params: query
  })
}

/**
 * 删除 货源预出库信息
 */
export function hyoutdel (id) {
  return request({
    url: '/tms/tmsdd/hyoutdel/' + id,
    method: 'get'
  })
}

/**
 * 删除 运单预出库信息
 */
export function ydoutdel (id) {
  return request({
    url: '/tms/tmsdd/ydoutdel/' + id,
    method: 'get'
  })
}
/**
 *
 短驳预订单库存分页查询
 */
export function dtYddOutpage (query) {
  return request({
    url: '/tms/tmsdd/dtoutpage',
    method: 'post',
    data: query
  })
}
/**
 * 短驳 预订单预出库信息
 */
export function dtYddOutcheck (query) {
  return request({
    url: '/tms/tmsdd/dtoutcheck',
    method: 'post',
    params: query
  })
}
/**
 *
 短驳-预订单预装删除操作
 */
export function dtYddOutdel (id) {
  return request({
    url: '/tms/tmsdd/dtoutdel/' + id,
    method: 'get'
  })
}
/**
 * 预订单 一键生成托运单
 */
export function instantGenerateYd (obj) {
  return request({
    url: '/tms/tmsyd/generateConsignment',
    method: 'post',
    data: obj
  })
}
/**
 * 预订单 批量生成货源单
 */
export function instantGenerateHy (ids) {
  return request({
    url: '/tms/tmshy/sourceDocuments',
    method: 'post',
    params: ids
  })
}
/**
 * 获取导入预订单状态
 */
export function returnImportYdd () {
  return request({
    url: '/tms/tmsdd/import/progress',
    method: 'get'
  })
}
