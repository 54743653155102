<template>
  <el-collapse>
      <el-card shadow="hover" style="height: 120px;">
          <el-upload
                  class="import-demo"
                  ref="upload"
                  :headers="headers"
                  :action="url"
                  :data="importData"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :before-remove="beforeRemove"
                  :auto-upload="false"
                  accept=".csv"
                  :before-upload="beforeAvatarUpload"
                  :limit="limitUpload"
                  :on-error="handleError"
                  :on-success="handleSuccess"
                  :on-exceed="handleExceed"
                  :on-change="handleChange"
                  :file-list="fileList">
              <el-button slot="trigger" size="mini" type="primary">选择文件</el-button>
              <el-button style="margin-left: 10px;" size="mini" type="success" @click="submitUpload" :loading="loading">开始导入</el-button>
              <el-button v-if="this.templateType === 'cbImport'"  size="mini" type="info" @click="cbDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'bjImport'"  size="mini" type="info" @click="bjDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'szImport'"  size="mini" type="info" @click="szDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'xjImport'"  size="mini" type="info" @click="xjDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'srmbImport'"  size="mini" type="info" @click="srmbDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'cbglImport'"  size="mini" type="info" @click="cbglDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'gzdxImport'"  size="mini" type="info" @click="gzdxDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'pldhcxImport'"  size="mini" type="info" @click="pldhcxDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'ncmbImport'"  size="mini" type="info" @click="ncmbDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'drgzImport'"  size="mini" type="info" @click="drgzDownload">下载模板</el-button>
              <div slot="tip" class="el-upload__tip">只能上传csv文件</div>
          </el-upload>
      </el-card>
  </el-collapse>
</template>
<script>

import store from '@/store/index'
import { validatenull } from '@/tdcommon/validate'
// import { getImportData } from '@/api/tms/tmsquotation'

export default {
  name: 'd2-container-import-detail',
  data () {
    return {
      loading: false,
      fileList: [],
      limitUpload: 1,
      formData: [],
      fileMsg: {},
      importData: { type: this.importType },
      headers: {
        'Authorization': 'Bearer ' + store.getters.access_token,
        'TENANT-ID': 1
      },
      markone: 0,
      marktwo: 0,
      keyMark: ''
    }
  },
  props: {
    // percentage: {
    //   type: Number,
    //   default: () => 0
    // },
    importVisible: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    multiple: {
      type: Boolean,
      default: () => false
    },
    url: {
      type: String,
      default: () => ''
    },
    templateType: {
      type: String,
      default: () => ''
    },
    importType: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    handleChange (file, fileList) {
      this.fileMsg = file
    },
    // 关闭时清空srclist
    clearSrcList () {
      this.$refs.upload.clearFiles() // 清空上传文件列表
    },
    // 下载有成本测算报价模板
    cbDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E6%9C%89%E6%88%90%E6%9C%AC%E6%B5%8B%E7%AE%97%E6%8A%A5%E4%BB%B7.csv'
    },
    // 下载有报价测算成本模板
    bjDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E6%9C%89%E6%8A%A5%E4%BB%B7%E6%B5%8B%E7%AE%97%E6%88%90%E6%9C%AC.csv'
    },
    // 下载有收支测算毛利模板
    szDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E6%9C%89%E6%94%B6%E6%94%AF%E6%B5%8B%E7%AE%97%E6%AF%9B%E5%88%A9.csv'
    },
    xjDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E8%AF%A2%E4%BB%B7%E5%8D%95%E6%98%8E%E7%BB%86.csv'
    },
    srmbDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E8%AE%BE%E5%AE%9A%E7%9B%AE%E6%A0%87%E6%94%B6%E5%85%A5%E8%A1%A8.csv'
    },
    cbglDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E6%88%90%E6%9C%AC%E7%AE%A1%E7%90%86%E6%98%8E%E7%BB%86.csv'
    },
    gzdxDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E6%89%B9%E9%87%8F%E7%9F%AD%E4%BF%A1%E6%A8%A1%E6%9D%BF.csv'
    },
    pldhcxDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E6%89%B9%E9%87%8F%E5%85%B1%E4%BA%AB%E5%AF%BC%E5%85%A5.csv'
    },
    ncmbDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E5%AF%BC%E5%85%A5%E5%B9%B4%E5%88%9D%E7%9B%AE%E6%A0%87%E6%98%8E%E7%BB%86.csv'
    },
    drgzDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E5%AF%BC%E5%85%A5%E8%BF%90%E5%8D%95%E8%B7%9F%E8%B8%AA%E4%BF%A1%E6%81%AF%E6%A8%A1%E6%9D%BF.csv'
    },
    // 上传
    submitUpload () {
      console.log('this.fileMsg', this.fileMsg)
      this.loading = true
      if (!validatenull(this.fileMsg)) { // fileMsg
        console.log('导入事件')
        this.$refs.upload.submit()
      } else {
        this.$message.error('请选择导入文件！')
        this.loading = false
      }
    },
    handleError (response) {
      this.loading = false
      return this.$confirm('请检查个人是否有权限进行导入')
    },
    handleSuccess (response, file, fileList) {
      if (response.code !== 0) {
        this.loading = false
        this.fileMsg = {} // 清空this.fileMsg
        this.$refs.upload.clearFiles() // 清空上传文件列表
        return this.$alert(response.msg)
      } else {
        this.loading = false
        this.fileMsg = {} // 清空this.fileMsg
        this.$refs.upload.clearFiles() // 清空上传文件列表
        this.keyMark = response.data
        console.log('response.data', response.data)
        this.$emit('importDetailMsg', response.data)
      }
    },
    handleRemove (file, fileList) {
    },
    handlePreview (file, fileList) {
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    // 判断导入的格式是不是csv，如果不是则提示
    beforeAvatarUpload (file, fileList) {
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'csv'
      // const extension2 = testmsg === 'xlsx'
      // const isLt2M = file.size / 1024 / 1024 < 2
      if (!extension) {
        this.$message.error('导入文件只支持 csv 格式！')
        return false
      }
      // if (!isLt2M) {
      //   this.$message.error('上传头像图片大小不能超过 2MB!')
      // }
      // return (extension || extension2) && isLt2M
      return extension
    },
    handleSelect (val) {
      this.$emit('change', val)
    }
  },
  watch: {
    importType (newVal, oldVal) {
      if (newVal) {
        this.importData = { type: newVal }
      }
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss">
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-import-detail/index.vue"
}
</vue-filename-injector>
