import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/tms/tmssg/page',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/tms/tmssg',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/tms/tmssg/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/tms/tmssg/' + id,
    method: 'delete'
  })
}

export function putObj (obj) {
  return request({
    url: '/tms/tmssg',
    method: 'put',
    data: obj
  })
}

/**
 * 事故 批量删除
 */
export function delObjs (ids) {
  return request({
    url: '/tms/tmssg/delsgids',
    method: 'post',
    params: ids
  })
}
/**
 * 事故 批量审单
 */
export function auditExeam (ids) {
  return request({
    url: '/tms/tmssg/auditExeam',
    method: 'post',
    params: ids
  })
}

/**
 * 事故 批量反审单
 */
export function unauditExeam (ids) {
  return request({
    url: '/tms/tmssg/unauditExeam',
    method: 'post',
    params: ids
  })
}
/**
 * 事故 批量审核
 */
export function audit (ids) {
  return request({
    url: '/tms/tmssg/audit',
    method: 'post',
    params: ids
  })
}

/**
 * 事故 批量反审核
 */
export function auditundo (ids) {
  return request({
    url: '/tms/tmssg/auditundo',
    method: 'post',
    params: ids
  })
}

/**
 * 事故 批量复审
 */
export function retrial (ids) {
  return request({
    url: '/tms/tmssg/retrial',
    method: 'post',
    params: ids
  })
}

/**
 * 事故 批量反复审
 */
export function unretrial (ids) {
  return request({
    url: '/tms/tmssg/unretrial',
    method: 'post',
    params: ids
  })
}

/*
* 通过托运单号查询*
*/
export function getSgListByTydId (ydNo) {
  return request({
    url: '/tms/tmssg/yddetails',
    method: 'get',
    params: ydNo
  })
}

/**
 * 上传阿里 事故中心保存到后台
 */
export function addUploadAliAccident (obj) {
  return request({
    url: '/tms/ossobject/accident',
    method: 'post',
    data: obj
  })
}
/**
 * 打印
 */
export function printSg (obj) {
  return request({
    url: '/tms/tmssg/reportPrinting',
    method: 'get',
    responseType: 'blob',
    params: obj
  })
}
/**
 * 打印
 */
export function getCostNo (eventType, ydNo) {
  return request({
    url: '/tms/tmssg/getDocNo',
    method: 'get',
    params: { eventType: eventType, ydNo: ydNo }
  })
}

export function fetchCensus (query) {
  return request({
    url: '/tms/tmssg/census/page',
    method: 'post',
    data: query
  })
}
