<template>
    <div class="operateChartLine" id="myThermometerChartLine" :style="{height:height, width: width}"></div>
</template>
<script>
// import { validatenull } from '@/tdcommon/validate'

export default {
  name: 'd2-container-echarts-operate-line',
  data () {
    return {
      xAxisData: [],
      temperature1: [],
      temperature2: [],
      temperature3: [],
      temperature4: []
    }
  },
  props: {
    height: {
      type: String,
      default: () => ''
    },
    width: {
      type: String,
      default: () => ''
    },
    lineData: {
      type: Array,
      default: () => []
    }
  },
  mounted () {
  },
  methods: {
    initChart (title, xAxisData, temperature1, temperature2, temperature3, temperature4) {
      // 引入基本模板
      var echarts = require('echarts/lib/echarts')
      // 引入折线图组件
      require('echarts/lib/chart/line')
      // 引入提示框和title组件
      require('echarts/lib/component/tooltip')
      require('echarts/lib/component/title')

      // 清空上一次值
      this.xAxisData = []
      this.temperature1 = []
      this.temperature2 = []
      this.temperature3 = []
      this.temperature4 = []
      // 赋值
      this.xAxisData = xAxisData
      this.temperature1 = temperature1
      this.temperature2 = temperature2
      this.temperature3 = temperature3
      this.temperature4 = temperature4

      let myChart = echarts.init(document.getElementById('myThermometerChartLine'))
      var colors = ['#55A8FD', 'rgba(50, 197, 233, 1)', 'rgba(159, 230, 184, 1)']
      var options = {
        color: colors,
        title: { text: title },
        tooltip: {
          trigger: 'axis'
        },
        toolbox: {
          feature: {
            // dataView: { show: true, readOnly: true },
            // magicType: { show: true, type: ['line', 'bar'] },
            // restore: {show: true},
            // saveAsImage: { show: true }
          }
        },
        legend: {
          data: ['温度1', '温度2', '温度3', '温度4']
          // selected: {
          //   '总毛利': false,
          //   '总收入': false
          // }
        },
        grid: {
          left: '4%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          name: '时间',
          boundaryGap: false,
          data: this.xAxisData,
          axisLine: {
            lineStyle: {
              color: '#969696'
            }
          },
          axisLabel: {
            // formatter: function (value) {
            //   return value.split('').join('\n')
            // }
          }
        },
        yAxis: {
          type: 'value',
          name: '温度(℃)',
          min: 0,
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#E8E8E9'
            }
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: '#969696'
            }
          }
        },
        series: [
          {
            name: '温度1',
            type: 'line',
            zlevel: 2,
            // barWidth: '10%',
            barGap: '100%',
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(85,168,253,0.2)'
              }, {
                offset: 1,
                color: 'rgba(85,168,253,0)'
              }])
            },
            data: this.temperature1
          },
          {
            name: '温度2',
            type: 'line',
            zlevel: 2,
            barGap: '100%',
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(50, 197, 233,0.2)'
              }, {
                offset: 1,
                color: 'rgba(50, 197, 233,0)'
              }])
            },
            data: this.temperature2
          },
          {
            name: '温度3',
            type: 'line',
            zlevel: 2,
            barGap: '100%',
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(159, 230, 184,0.2)'
              }, {
                offset: 1,
                color: 'rgba(159, 230, 184,0)'
              }])
            },
            data: this.temperature3
          },
          {
            name: '温度4',
            type: 'line',
            zlevel: 2,
            barGap: '100%',
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(50, 197, 233,0.2)'
              }, {
                offset: 1,
                color: 'rgba(50, 197, 233,0)'
              }])
            },
            data: this.temperature4
          }]
      }
      myChart.setOption(options, true)

      // myChart.on('legendselectchanged', function (obj) {
      //   var name = obj.name
      //   var selected = obj.selected
      //   if (name === '总票数') {
      //     selected.总收入 = false
      //     selected.总毛利 = false
      //   } else if (name === '总收入') {
      //     selected.总票数 = false
      //     selected.总毛利 = false
      //   } else if (name === '总毛利') {
      //     selected.总票数 = false
      //     selected.总收入 = false
      //   }
      //   options.legend.selected = selected
      //   myChart.setOption(options, true)
      // })
    }
  },
  watch: {
  }
}
</script>

<style lang="scss">
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-echarts-thermometer-line/index.vue"
}
</vue-filename-injector>
