import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/tms/tmsCustomers/page',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/tms/tmsCustomers/insertTmsCustomers',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/tms/tmsCustomers/queryPieceData/' + id,
    method: 'get'
  })
}

export function putObj (obj) {
  return request({
    url: '/tms/tmsCustomers/updateTmsCustomers',
    method: 'post',
    data: obj
  })
}

/**
 * 批量删除
 */
export function delObjs (ids) {
  return request({
    url: '/tms/tmsCustomers/batchDeleteData',
    method: 'post',
    params: ids
  })
}
/**
 * 判断是否重复
 */
export function getDetails (obj) {
  return request({
    url: '/tms/tmsCustomers/judgeWhetherRepeat',
    method: 'post',
    params: { customerName: obj }
  })
}
/**
 * 导入
 */
export function excelImport (file) {
  return request({
    url: '/tms/tmsCustomers/importTmsCustomersData',
    method: 'post',
    params: file
  })
}
/**
 * 通过ids进行审核
 */
export function passExamine (ids) {
  return request({
    url: '/tms/tmsCustomers/examine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行反审核
 */
export function passUnExamine (ids) {
  return request({
    url: '/tms/tmsCustomers/unExamine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行拒审
 */
export function refuseExamine (ids) {
  return request({
    url: '/tms/tmsCustomers/unExamineFailed',
    method: 'post',
    params: ids
  })
}
/**
 * 判断是否重复
 */
export function getCreditCode (obj) {
  return request({
    url: '/tms/tmsCustomers/judgeCreditCodeRepeat',
    method: 'post',
    params: { creditCode: obj }
  })
}
// 客源管理  跳转 引入与评审
export function getIntroduceMsg (id) {
  return request({
    url: '/tms/tmsCustomers/adoptQueryReviewData/' + id,
    method: 'get'
  })
}
/**
 * 客源管理导入 返回结果
 */
export function returnPassengerImport () {
  return request({
    url: '/tms/tmsCustomers/import/progress',
    method: 'get'
  })
}
