import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/platform/e6/getVehcileList',
    method: 'get',
    params: query
  })
}
/**
 * 定位 通过车牌号获取返回数据
 */
export function getGpsPosition (obj) {
  return request({
    url: '/platform/e6/getVehcileInfo',
    method: 'get',
    params: { vehicle: obj }
  })
}
/**
 * 轨迹 通过车牌号获取返回数据
 */
export function getGpsTrack (carNo, startDate, endDate) {
  return request({
    url: '/platform/e6/getTrackDetail',
    method: 'post',
    data: { vehicle: carNo, startDate: startDate, endDate: endDate }
  })
}
/**
 * 获取温度信息
 */
export function getTemperature (carNo, startDate, endDate, intervalTime) {
  return request({
    url: '/platform/e6/getVehicleTemperatureInfo',
    method: 'post',
    data: { vehicle: carNo, startDate: startDate, endDate: endDate, intervalTime: intervalTime }
  })
}
