<template>
  <div class="td-table-hear button-collect-box" slot="header" v-if="isShow">
    <!-- el-row type="flex" v-if="!checkNum" justify="space-between" -->
    <el-row type="flex" justify="space-between">
      <el-col :span="leftCol">
        <div v-if="buttonShow">
          <!-- 一级按钮 -->
          <el-button v-for="item in buttonList.buttonFirst" v-show="permissions[item.permission]" :key="item.id" type="primary" :icon="item.icon" :title="item.title" size="mini" :loading="firstButtonLoading" @click="handleFirstClick(item)">{{item.name}}</el-button>
          <div v-for="item in buttonList.buttonMore" :key="item.id" style="display: inline;">
            <!-- <el-divider direction="vertical"></el-divider> -->
            <!-- 二级按钮 和 组合按钮 的颜色区分-->
            <el-button :type="(item.icon === 'el-icon-delete' || item.icon === 'el-icon-close' || item.icon === 'el-icon-refresh') ? 'danger' : (item.icon === 'el-icon-edit' || item.icon === 'el-icon-finished') ? 'primary' : (item.icon === 'el-icon-check' || item.icon === 'el-icon-document') ? 'success' : (item.icon === 'el-icon-printer' || item.icon === 'el-icon-view' || item.icon === 'el-icon-question' || item.icon === 'el-icon-tickets' || item.icon === 'el-icon-zoom-out') ? 'info' : 'warning'"
                       :icon="item.icon"
                       v-show="item.menuId !== 10364 && permissions[item.permission]"
                       style="margin-left: 10px;"
                       size="mini"
                       @click="handleSecondClick(item)">
              {{item.name}}
            </el-button>
          </div>
          <div v-for="item in buttonList.buttonGroup" :key="item.id" style="display: inline; margin-left: 10px;">
            <d2-container-more-button :moreButton="item" @onClick="handleClickMore"></d2-container-more-button>
          </div>
        </div>
      </el-col>
      <el-col :span="rightCol" style="text-align:right">
        <el-dropdown trigger="click" @command="handleCommand" v-if="operateMore">
          <el-button icon="el-icon-menu" size="mini" style="margin-right: 10px;" title="">更多操作</el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="current" v-if="pagePresentState"><i class="el-icon-document-checked"></i>导出当前页</el-dropdown-item>
            <el-dropdown-item command="all" v-if="pageState"><i class="el-icon-folder-checked"></i>导出全部页</el-dropdown-item>
            <el-dropdown-item command="backup" v-if="pageBackupState"><i class="el-icon-download"></i>导出报表</el-dropdown-item>
            <el-dropdown-item command="exportSecond" v-if="pageExportSecondState"><i class="el-icon-download"></i>导出二级表</el-dropdown-item>
            <el-dropdown-item command="print" v-if="pagePrintState"><i class="el-icon-printer"></i>打印报价单</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button style="width: 28px;padding: 7px;" v-if="saveColumnSort" icon="el-icon-finished" size="mini" title="保存列序" @click="saveColumnSortClick"></el-button>
        <el-button style="width: 28px;padding: 7px;" v-if="refreshShow" icon="el-icon-refresh" size="mini" title="刷新" @click="refreshClick"></el-button>
        <el-button style="width: 28px;padding: 7px;" v-if="newTableFun" icon="el-icon-search" size="mini" title="快捷过滤" @click="newTableSearch"></el-button>
        <el-button style="width: 28px;padding: 7px;" v-if="newTableFun" icon="el-icon-magic-stick" size="mini" title="清空过滤" @click="newTableClear"></el-button>
        <el-button style="width: 28px;padding: 7px;" v-if="isSelfDefined" icon="el-icon-setting" size="mini" title="表格列显示设置" @click="tableShow"></el-button>
        <el-button style="width: 28px;padding: 7px;" :icon="hideState ? 'el-icon-arrow-up': 'el-icon-arrow-down'" :values='hideState' size="mini" :title="hideState ? '隐藏查询': '显示查询'" @click="hideSearch"></el-button>
      </el-col>
    </el-row>
    <!-- <el-row v-else type="flex" justify="space-between"> -->
    <el-row type="flex" justify="space-between" style="display: none;">
      <el-col :span="15">
        <el-link :underline="false" style="color: #606266;" size="mini">已选<span
          style="margin-left: 5px;margin-right: 5px;color: #409EFF;font-weight: bold;">{{checkNum}}</span>项
        </el-link>
        <div v-for="item in buttonList.buttonMore" :key="item.id" style="display: inline;">
          <el-divider direction="vertical"></el-divider>
          <!-- 二级按钮 和 组合按钮 的颜色区分-->
          <el-button :type="item.icon === 'el-icon-delete' ? 'danger' : item.icon === 'el-icon-edit' ? 'primary' : (item.icon === 'el-icon-check' || item.icon === 'el-icon-close') ? 'success' : 'warning'"
             :icon="item.icon"
             style="margin-left: 10px;margin-right: 10px;"
             size="mini"
             @click="handleSecondClick(item)">
            {{item.name}}
          </el-button>
        </div>
        <div v-for="item in buttonList.buttonGroup" :key="item.id" style="display: inline;">
          <el-divider direction="vertical"></el-divider>
          <d2-container-more-button :moreButton="item" @onClick="handleClickMore"></d2-container-more-button>
        </div>
      </el-col>
      <el-col :span="5" style="text-align:right">
        <el-button icon="el-icon-close" size="mini" title="清空选择" circle @click="handleClose"></el-button>
      </el-col>
    </el-row>
    <d2-table-setting v-if="dialogVisible.show" :settingCode="dialogVisible" @onSave="onSave" @closeClick="settingClose"></d2-table-setting>
  </div>
</template>

<script>
import { addTableUser, getTableHearhUser } from '@/api/sys.ui'
import { mapGetters } from 'vuex'
import { validatenull } from '@/tdcommon/validate'
import { getDecodeStore, removeStore, setEncodeStore } from '@/tdcommon/store'

export default {
  name: 'd2-container-table-header',
  computed: {
    ...mapGetters(['permissions'])
  },
  props: {
    // 组件按钮信息
    buttonList: {
      type: Object,
      default: function () {
        return {}
      }
    },
    checkNum: {
      type: Number,
      default: () => 0
    },
    ModularName: {
      type: String,
      default: () => ''
    },
    // 按钮 是否显示
    buttonShow: {
      type: Boolean,
      default: () => true
    },
    // 更多操作 是否显示
    operateMore: {
      type: Boolean,
      default: () => true
    },
    // 刷新 是否显示
    refreshShow: {
      type: Boolean,
      default: () => true
    },
    // 全部页导出 是否显示
    pageState: {
      type: Boolean,
      default: () => true
    },
    // 当前页导出 是否显示
    pagePresentState: {
      type: Boolean,
      default: () => true
    },
    pageBackupState: {
      type: Boolean,
      default: () => false
    },
    pageExportSecondState: {
      type: Boolean,
      default: () => false
    },
    pagePrintState: {
      type: Boolean,
      default: () => false
    },
    // 一级按钮加载效果，默认false
    firstButtonLoading: {
      type: Boolean,
      default: () => false
    },
    // 保存列序 是否显示
    saveColumnSort: {
      type: Boolean,
      default: () => true
    },
    // 新表格功能是否显示
    newTableFun: {
      type: Boolean,
      default: () => false
    },
    // 表格自定义是否显示
    isSelfDefined: {
      type: Boolean,
      default: () => true
    },
    leftCol: {
      type: Number,
      default: () => 17
    },
    rightCol: {
      type: Number,
      default: () => 7
    }
  },
  // { title: '自定义表格列显示', show: false, isSetting: false, sysCheck: '启用自定义表格列', DataTransfer: [] }
  data () {
    return {
      hideState: true,
      isShow: true,
      dialogVisible: { title: '自定义列表显示', show: false, isSetting: false, sysCheck: '启用自定义列表界面', DataTransfer: [] }
    }
  },
  mounted () {
    this.getHears()
  },
  methods: {
    // 取得按钮组件信息
    getHears () {
      this.isShow = false
      let buttons = getDecodeStore({ name: 'bu' + this.ModularName })
      if (validatenull(buttons)) {
        getTableHearhUser(this.ModularName).then(response => {
          this.buttonList.buttonFirst = response.data.buttonFirst
          this.buttonList.buttonMore = response.data.buttonMore
          this.buttonList.buttonGroup = this.setGroudShow(response.data.buttonGroup)
          this.buttonList.tableColumns = response.data.tableColumns
          this.buttonList.isCustTableColumns = response.data.isCustTableColumns
          setEncodeStore('bu' + this.ModularName, this.buttonList)
          this.isShow = true
        }).catch(() => {
          this.isShow = true
        })
      } else {
        this.buttonList.buttonFirst = buttons.buttonFirst
        this.buttonList.buttonMore = buttons.buttonMore
        this.buttonList.buttonGroup = buttons.buttonGroup
        this.buttonList.tableColumns = buttons.tableColumns
        this.buttonList.isCustTableColumns = buttons.isCustTableColumns
        this.isShow = true
      }
    },
    // 一级按钮点击
    handleFirstClick (values) {
      this.$emit('handleFirstClick', values)
    },
    // 二级按钮点击
    handleSecondClick (values) {
      this.$emit('handleSecondClick', values)
    },
    // 刷新按钮点击事件
    refreshClick () {
      this.$emit('refreshClick')
    },
    handleCommand (command) {
      this.$emit('handleCommand', command)
    },
    newTableSearch () {
      this.$emit('newTableSearch')
    },
    newTableClear () {
      this.$emit('newTableClear')
    },
    // 保存表格列拖拽后的顺序
    saveColumnSortClick () {
      this.$emit('saveColumnSort')
    },
    saveTableColumnSort (cols) {
      let displayId = this.buttonList.tableColumns[0].dispalyId
      let showValues = []
      cols.forEach(item => {
        for (let index in this.buttonList.tableColumns) {
          if (this.buttonList.tableColumns[index].tableUserDefine === '0') {
            this.buttonList.tableColumns[index].index = parseInt(index)
            if (this.buttonList.tableColumns[index].prop === item.colId) {
              showValues.push(this.buttonList.tableColumns[index].id)
            }
          }
        }
      })
      let obj = { showList: showValues, displayId: displayId }
      addTableUser(obj).then(response => {
        // 成功时
        removeStore({ name: 'tb' + this.ModularName, type: 'session' })
        removeStore({ name: 'bu' + this.ModularName, type: 'session' })
        this.getHears()
        this.$emit('tableShow')
      })
    },
    // 表格列显示设置点击事件
    tableShow () {
      this.dialogVisible.isSetting = this.buttonList.isCustTableColumns
      this.dialogVisible.DataTransfer = this.buttonList.tableColumns
      this.dialogVisible.show = true
    },
    // 隐藏查询条件界面
    hideSearch () {
      this.hideState = !this.hideState
      this.$emit('hideSearch', this.hideState)
    },
    // 组按点击事件
    handleClickMore (values) {
      this.$emit('handleClickMore', values)
    },
    // 点击了关闭二级组按钮
    handleClose () {
      this.$emit('handleClose')
    },
    settingClose () {
      this.dialogVisible.show = false
    },
    // 自定义表格显示列
    onSave (values) {
      this.dialogVisible.show = false
      removeStore({ name: 'tb' + this.ModularName, type: 'session' })
      removeStore({ name: 'bu' + this.ModularName, type: 'session' })
      this.getHears()
      this.$emit('tableShow', values)
    },
    setGroudShow (buttonGroup) {
      let buttonList = []
      if (!validatenull(buttonGroup)) {
        buttonGroup.forEach(item => {
          let buttonch = []
          item.forEach(itemch => {
            if (this.permissions[itemch.permission] || !validatenull(this.permissions[itemch.permission])) {
              itemch.check = true
              buttonch.push(itemch)
            }
          })
          if (!validatenull(buttonch)) {
            buttonList.push(buttonch)
          }
        })
      }
      return buttonList
    }
  }
}
</script>

<style lang="scss" scoped>
.menuDown {
  font-size: 18px;
  color: #000;
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-table-header/index.vue"
}
</vue-filename-injector>
