import layoutHeaderAside from '@/layout/header-aside'

// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

/**
 * 在主框架内显示
 */
const frameIn = [
  {
    path: '/',
    redirect: { name: 'index' },
    component: layoutHeaderAside,
    children: [
      // 首页
      {
        path: '/index',
        name: 'index',
        meta: {
          auth: true,
          cache: true
        },
        component: _import('system/index')
      },
      // 系统 前端日志
      {
        path: '/log',
        name: 'log',
        meta: {
          title: '前端日志',
          auth: true
        },
        component: _import('system/log')
      },
      // 系统 前端日志
      {
        path: '/info',
        name: 'info',
        meta: {
          title: '个人信息',
          auth: true
        },
        component: _import('admin/info')
      },
      {
        path: '/401',
        name: '401',
        hidden: true,
        component: _import('system/error/401')
      },
      // 刷新页面 必须保留
      {
        path: '/refresh',
        name: 'refresh',
        hidden: true,
        component: _import('system/function/refresh')
      },
      // 页面重定向 必须保留
      {
        path: '/redirect/:route*',
        name: 'redirect',
        hidden: true,
        component: _import('system/function/redirect')
      }
    ]
  }
]

/**
 * 在主框架之外显示
 */
const frameOut = [
  // 登录
  {
    path: '/login',
    name: 'login',
    component: _import('system/login')
  }
]

/**
 * 临时新增修改配合界面
 */
const addEdit = [
  {
    path: '/basic',
    component: layoutHeaderAside,
    children: [
      {
        path: '/basic/customer/customeradd/index',
        name: 'customeradd',
        component: _import(`basic/customer/customeradd/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '新增客户资料'
        }
      }
    ]
  },
  {
    path: '/basic',
    component: layoutHeaderAside,
    children: [
      {
        path: '/basic/customer/customeredit/index',
        name: 'customeredit',
        component: _import(`basic/customer/customeredit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑客户详情'
        }
      }
    ]
  },
  {
    path: '/basic',
    component: layoutHeaderAside,
    children: [
      {
        path: '/basic/carrier/carrieradd/index',
        name: 'carrieradd',
        component: _import(`basic/carrier/carrieradd/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '新增承运单位'
        }
      }
    ]
  },
  {
    path: '/basic',
    component: layoutHeaderAside,
    children: [
      {
        path: '/basic/carrier/carrieredit/index',
        name: 'carrieredit',
        component: _import(`basic/carrier/carrieredit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '承运单位详情'
        }
      }
    ]
  },
  {
    path: '/basic',
    component: layoutHeaderAside,
    children: [
      {
        path: '/basic/customerprice/edit/index',
        name: 'customerprice_edit',
        component: _import(`basic/customerprice/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑客户运价'
        }
      }
    ]
  },
  {
    path: '/tms',
    component: layoutHeaderAside,
    children: [
      {
        path: '/tms/bus/order/edit/index',
        name: 'order_edit',
        component: _import(`tms/bus/order/edit/index`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑订单'
        }
      }
    ]
  },
  {
    path: '/tms',
    component: layoutHeaderAside,
    children: [
      {
        path: '/tms/bus/waybill/edit/index',
        name: 'waybill_edit',
        component: _import(`tms/bus/waybill/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑托运单'
        }
      }
    ]
  },
  {
    path: '/tms',
    component: layoutHeaderAside,
    children: [
      {
        path: '/tms/bus/waybill/add/index',
        name: 'waybill_add',
        component: _import(`tms/bus/waybill/add/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '新增托运单'
        }
      }
    ]
  },
  {
    path: '/tms',
    component: layoutHeaderAside,
    children: [
      {
        path: '/tms/bus/advorder/edit/index',
        name: 'advorder_edit',
        component: _import(`tms/bus/advorder/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑预订单'
        }
      }
    ]
  },
  {
    path: '/tms',
    component: layoutHeaderAside,
    children: [
      {
        path: '/tms/bus/shortbill/edit/index',
        name: 'shortbill_edit',
        component: _import(`tms/bus/shortbill/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑短驳单'
        }
      }
    ]
  },
  {
    path: '/tms',
    component: layoutHeaderAside,
    children: [
      {
        path: '/tms/bus/transfer/edit/index',
        name: 'transfer_edit',
        component: _import(`tms/bus/transfer/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑中转单'
        }
      }
    ]
  },
  {
    path: '/tms',
    component: layoutHeaderAside,
    children: [
      {
        path: '/tms/bus/aviation/edit/index',
        name: 'aviation_edit',
        component: _import(`tms/bus/aviation/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑航空单'
        }
      }
    ]
  },
  {
    path: '/tms',
    component: layoutHeaderAside,
    children: [
      {
        path: '/tms/bus/delivery/edit/index',
        name: 'delivery_edit',
        component: _import(`tms/bus/delivery/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑送货单'
        }
      }
    ]
  },
  {
    path: '/singular',
    component: layoutHeaderAside,
    children: [
      {
        path: '/singular/transaction/edit/index',
        name: 'transaction_edit',
        component: _import(`singular/transaction/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑异动单'
        }
      }
    ]
  },
  {
    path: '/singular',
    component: layoutHeaderAside,
    children: [
      {
        path: '/singular/accident/accidentadd/index',
        name: 'accident_add',
        component: _import(`singular/accident/accidentadd/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '登记事故单'
        }
      }
    ]
  },
  {
    path: '/singular',
    component: layoutHeaderAside,
    children: [
      {
        path: '/singular/accident/accidentedit/index',
        name: 'accident_edit',
        component: _import(`singular/accident/accidentedit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '处理异动单'
        }
      }
    ]
  },
  {
    path: '/wealth',
    component: layoutHeaderAside,
    children: [
      {
        path: '/wealth/diversity/edit/index',
        name: 'diversity_edit',
        component: _import(`wealth/diversity/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '对账差异管理'
        }
      }
    ]
  },
  {
    path: '/wealth',
    component: layoutHeaderAside,
    children: [
      {
        path: '/wealth/receivable/edit/index',
        name: 'receivable_edit',
        component: _import(`wealth/receivable/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑应收账单'
        }
      }
    ]
  },
  {
    path: '/wealth',
    component: layoutHeaderAside,
    children: [
      {
        path: '/wealth/payable/edit/index',
        name: 'payable_edit',
        component: _import(`wealth/payable/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑应付账单'
        }
      }
    ]
  },
  {
    path: '/fleet',
    component: layoutHeaderAside,
    children: [
      {
        path: '/fleet/vehiclemanagement/edit/index',
        name: 'vehiclemanagement_edit',
        component: _import(`fleet/vehiclemanagement/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑车辆管理'
        }
      }
    ]
  },
  {
    path: '/fleet',
    component: layoutHeaderAside,
    children: [
      {
        path: '/fleet/costmanagement/edit/index',
        name: 'costmanagement_edit',
        component: _import(`fleet/costmanagement/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑费用管理'
        }
      }
    ]
  },
  {
    path: '/fleet',
    component: layoutHeaderAside,
    children: [
      {
        path: '/fleet/driverFile/edit/index',
        name: 'driverFile_edit',
        component: _import(`fleet/driverFile/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑司机档案'
        }
      }
    ]
  },
  {
    path: '/bms',
    component: layoutHeaderAside,
    children: [
      {
        path: '/bms/bank/dealHistory/index',
        name: 'deal_history',
        component: _import(`bms/bank/dealHistory/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '交易历史'
        }
      }
    ]
  },
  {
    path: '/customer',
    component: layoutHeaderAside,
    children: [
      {
        path: '/customer/track/temperature/index',
        name: 'temperature',
        component: _import(`customer/track/temperature/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '温控表'
        }
      }
    ]
  },
  {
    path: '/bms',
    component: layoutHeaderAside,
    children: [
      {
        path: '/bms/pleasepay/edit/index',
        name: 'repaymanage_edit',
        component: _import(`bms/pleasepay/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑请款单'
        }
      }
    ]
  },
  {
    path: '/bms',
    component: layoutHeaderAside,
    children: [
      {
        path: '/bms/bookkeep/edit/index',
        name: 'receiptpayment_edit',
        component: _import(`bms/bookkeep/edit/index.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '编辑收付款'
        }
      }
    ]
  },
  {
    path: '/reportformOperate',
    component: layoutHeaderAside,
    children: [
      {
        path: '/reportformOperate/waybillgros/detail',
        name: 'waybillgros_detail',
        component: _import(`reportformOperate/waybillgros/detail.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '单票运单毛利分析'
        }
      }
    ]
  },
  {
    path: '/customer',
    component: layoutHeaderAside,
    children: [
      {
        path: '/customer/thermometer/details',
        name: 'thermometer_details',
        component: _import(`customer/thermometer/details.vue`),
        meta: {
          auth: true,
          cache: true,
          title: '温度计明细'
        }
      }
    ]
  }
]

/**
 * 错误页面
 */
const errorPage = [
  {
    path: '*',
    name: '404',
    component: _import('system/error/404')
  }
]

// 导出需要显示菜单的
export const frameInRoutes = frameIn
export const frameOutRoutes = frameOut
export const frameErrorRoutes = errorPage
export const frameEditRoutes = addEdit

// 重新组织后导出
export default [
  ...frameIn,
  ...frameOut,
  ...addEdit
]
