import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/tms/tmsIntroduce/page',
    method: 'post',
    data: query
  })
}

/**
 * 根据id查询引入与评审信息
 */
export function getObj (id) {
  return request({
    url: '/tms/tmsIntroduce/queryPieceData/' + id,
    method: 'get'
  })
}
/**
 * 修改保存
 */
export function putObj (obj) {
  return request({
    url: '/tms/tmsIntroduce/updateTmsIntroduce',
    method: 'post',
    data: obj
  })
}

/**
 * 批量删除
 */
export function delObjs (ids) {
  return request({
    url: '/tms/tmsIntroduce/batchDeleteData',
    method: 'delete',
    params: ids
  })
}
/**
 * 判断是否重复
 */
export function getDetails (obj) {
  return request({
    url: '/tms/dtcustomer/details/' + obj,
    method: 'get'
  })
}
/**
 * 通过ids进行市场审核
 */
export function cusMarket (ids) {
  return request({
    url: '/tms/tmsIntroduce/auditMarket',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行市场反审核
 */
export function cusUnMarket (ids) {
  return request({
    url: '/tms/tmsIntroduce/unAuditMarket',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行财务审核
 */
export function cusFinance (ids) {
  return request({
    url: '/tms/tmsIntroduce/auditFinance',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行财务反审核
 */
export function cusUnFinance (ids) {
  return request({
    url: '/tms/tmsIntroduce/unAuditFinance',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行运营审核
 */
export function cusOperate (ids) {
  return request({
    url: '/tms/tmsIntroduce/auditOperate',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行运营反审核
 */
export function cusUnOperate (ids) {
  return request({
    url: '/tms/tmsIntroduce/unAuditOperate',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行人力审核
 */
export function cusPersonnel (ids) {
  return request({
    url: '/tms/tmsIntroduce/auditPersonnel',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行人力反审核
 */
export function cusUnPersonnel (ids) {
  return request({
    url: '/tms/tmsIntroduce/unAuditPersonnel',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行采购审核
 */
export function cusPurchase (ids) {
  return request({
    url: '/tms/tmsIntroduce/auditPurchase',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行采购反审核
 */
export function cusUnPurchase (ids) {
  return request({
    url: '/tms/tmsIntroduce/unAuditPurchase',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行副总审核
 */
export function cusPresident (ids) {
  return request({
    url: '/tms/tmsIntroduce/auditPresident',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行副总反审核
 */
export function cusUnPresident (ids) {
  return request({
    url: '/tms/tmsIntroduce/unAuditPresident',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行总经理审核
 */
export function cusManager (ids) {
  return request({
    url: '/tms/tmsIntroduce/auditManager',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行总经理反审核
 */
export function cusUnManager (ids) {
  return request({
    url: '/tms/tmsIntroduce/unAuditManager',
    method: 'post',
    params: ids
  })
}
/**
 * 上传阿里
 */
export function uploadAliIntroduce (obj) {
  return request({
    url: '/tms/ossobject/introduceAndReview',
    method: 'post',
    data: obj
  })
}
/**
 * 打印
 */
export function printIntroduce (obj) {
  return request({
    url: '/tms/tmsIntroduce/printingIntroduceReview',
    method: 'post',
    responseType: 'blob',
    params: obj
  })
}
/**
 * 进程状态
 */
export function updateProcessState (obj) {
  return request({
    url: '/tms/tmsIntroduce/updateProcessState',
    method: 'post',
    params: obj
  })
}
/**
 * 通过ids进行 市场拒审
 */
export function auditMarketFailed (obj) {
  return request({
    url: '/tms/tmsIntroduce/auditMarketFailed',
    method: 'post',
    params: obj
  })
}
/**
 * 通过ids进行 市场拒审
 */
export function auditPurchaseFailed (obj) {
  return request({
    url: '/tms/tmsIntroduce/auditPurchaseFailed',
    method: 'post',
    params: obj
  })
}
/**
 * 通过ids进行 运营拒审
 */
export function auditOperateFailed (obj) {
  return request({
    url: '/tms/tmsIntroduce/auditOperateFailed',
    method: 'post',
    params: obj
  })
}
/**
 * 通过ids进行 人力拒审
 */
export function auditPersonnelFailed (obj) {
  return request({
    url: '/tms/tmsIntroduce/auditPersonnelFailed',
    method: 'post',
    params: obj
  })
}
/**
 * 通过ids进行 财务拒审
 */
export function auditFinanceFailed (obj) {
  return request({
    url: '/tms/tmsIntroduce/auditFinanceFailed',
    method: 'post',
    params: obj
  })
}
/**
 * 通过ids进行 副总拒审
 */
export function auditPresidentFailed (obj) {
  return request({
    url: '/tms/tmsIntroduce/auditPresidentFailed',
    method: 'post',
    params: obj
  })
}
/**
 * 通过ids进行 总经理拒审
 */
export function auditManagerFailed (obj) {
  return request({
    url: '/tms/tmsIntroduce/auditManagerFailed',
    method: 'post',
    params: obj
  })
}
/**
 * 查询审批流程信息
 */
export function getApproveData () {
  return request({
    url: '/tms/tmsWorkflow/queryWorkflowData',
    method: 'get'
  })
}
/**
 * 新增或修改 保存审批流程信息
 */
export function saveApproveData (obj) {
  return request({
    url: '/tms/tmsWorkflow/insertOrUpdateWork',
    method: 'post',
    data: obj
  })
}
