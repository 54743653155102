import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/tms/tmsdt/page',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/tms/tmsdt',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/tms/tmsdt/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/tms/tmsdt/' + id,
    method: 'delete'
  })
}

export function putObj (obj) {
  return request({
    url: '/tms/tmsdt',
    method: 'put',
    data: obj
  })
}
/**
 * 通过ids批量删除短驳单
 */
export function dtdelObjs (ids) {
  return request({
    url: '/tms/tmsdt/deldtids',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行短驳单审单
 */
export function dtAudit (ids) {
  return request({
    url: '/tms/tmsdt/audit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行短驳单反审单
 */
export function dtUnaudit (ids) {
  return request({
    url: '/tms/tmsdt/unaudit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行短驳单审核
 */
export function dtExamine (ids) {
  return request({
    url: '/tms/tmsdt/examine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行短驳单反审核
 */
export function dtUnexamine (ids) {
  return request({
    url: '/tms/tmsdt/unexamine',
    method: 'post',
    params: ids
  })
}
/*
* 短驳单详情接口*
*/
export function getDtDetails (id) {
  return request({
    url: '/tms/tmsdt/dtdetails/' + id,
    method: 'get'
  })
}
/**
 * 短驳单更新接口
 */
export function dtupdateZZ (obj) {
  return request({
    url: '/tms/tmsdt/updatedt',
    method: 'post',
    data: obj
  })
}
/**
 * 新增短驳单保存
 */
export function dtsavaZZ (obj) {
  return request({
    url: '/tms/tmsdt/savedt',
    method: 'post',
    data: obj
  })
}
/*
* 通过id查询*
*/
export function dtedit (id) {
  return request({
    url: '/tms/tmsdt/dtedit/' + id,
    method: 'get'
  })
}
/*
* 通过托运单ydNo查询*
*/
export function getDbListByTydId (ydNo) {
  return request({
    url: '/tms/tmsdt/yddetails',
    method: 'get',
    params: ydNo
  })
}
/**
 * 上传阿里 短驳单保存到后台
 */
export function uploadAliDt (obj) {
  return request({
    url: '/tms/ossobject/dt',
    method: 'post',
    data: obj
  })
}
/**
 * 通过ids进行短驳单复审
 */
export function dtRetial (ids) {
  return request({
    url: '/tms/tmsdt/retrial',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行短驳单反复审
 */
export function dtUnretrial (ids) {
  return request({
    url: '/tms/tmsdt/unretrial',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids生成费用异动
 */
export function generateTransaction (ids) {
  return request({
    url: '/tms/tmsdt/dtBatchGenerateChange',
    method: 'post',
    params: ids
  })
}
