<template>
  <el-select class="td_select"
             v-model="values"
             value-key="mdmCode"
             default-first-option
             filterable
             remote
             reserve-keyword
             placeholder="请输入关键词"
             :clearable="clearable"
             :disabled="disabled"
             :remote-method="remoteMethod"
             :loading="loading">
    <el-option
        class="td_option"
        v-for="(item, index) in options"
        :key="index"
        :label="item.mdmCode+ '  ' +item.customerName"
        :value="{ mdmCode: item.mdmCode, customerName: item.customerName }"
        @click.native="handleSelect(item)">
      <span style="float: left;width: 130px;"><i class="fa fa-gg-circle" :style="{'color':item.sourceType==='cus'?'#f78989':'#66b1ff','margin-right': '5px'}"></i>{{ item.mdmCode }}</span>
      <span style="float: left; color: #8492a6; font-size: 13px;">{{ item.customerName }}</span>
    </el-option>
  </el-select>
</template>
<script>
import { haireOjbName } from '@/api/basic/comlist'
import { validatenull } from '@/tdcommon/validate'
// 油卡账户 主卡卡号
export default {
  name: 'd2-container-masterAccount',
  data () {
    return {
      values: {},
      options: [],
      loading: false,
      mdmStrMark: ''
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    sourcetype: {
      type: String,
      default: () => ''
    },
    mdmstr: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    remoteMethod (query) {
      this.loading = true
      var Searchquery = validatenull(query) ? '' : query
      haireOjbName(Searchquery, this.sourcetype).then(response => {
        this.loading = false
        if (response.code === 0) {
          this.options = response.data
        }
      }).catch(() => {
        this.loading = false
      })
    },
    handleSelect (val) {
      this.values = { mdmCode: val.mdmCode, customerName: val.customerName }
      this.$emit('change', val)
    },
    getStartValues (val) {
      if (!validatenull(this.mdmstr)) {
        let model = this.$parent.elForm.model[this.mdmstr]
        if (!validatenull(model)) {
          this.values = Object.assign({ mdmCode: model, customerName: val })
        } else {
          this.values = Object.assign({ mdmCode: '', customerName: val })
        }
      }
    }
  },
  watch: {
    values: function (val, oldVal) {
      this.$emit('input', validatenull(val) ? '' : val.customerName)
    },
    value: function (val, oldVal) {
      if (!validatenull(this.options) && val !== oldVal) {
        this.remoteMethod(val)
        this.getStartValues(val)
      }
    }
  },
  mounted () {
    this.remoteMethod(this.value)
    this.getStartValues(this.value)
  }
}
</script>

<style lang="scss" scoped>
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-mdmselect/index.vue"
}
</vue-filename-injector>
