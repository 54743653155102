<template>
  <el-select class="td_select"
             v-model="values"
             style="width: 100%"
             default-first-option
             filterable
             remote
             allow-create
             reserve-keyword
             placeholder="项目名称"
             :remote-method="remoteMethod"
             :clearable="clearable"
             :disabled="disabled"
             :loading="loading"
             :multiple="multiple">
    <el-option
      class="td_option"
      v-for="(item, index) in options"
      :key="index"
      :label="item.customerName"
      :value="item.customerName"
      @click.native="handleSelect(item)">
      <span style="float: left; width: 260px;"><i class="fa fa-gg-circle" :style="{'color': '#66b1ff','margin-right': '5px'}"></i>{{ item.contractNo }}</span>
      <span style="float: left; color: #8492a6; font-size: 13px; width: 200px;">{{ item.customerName }}</span>
    </el-option>
  </el-select>
</template>
<script>

import { validatenull } from '@/tdcommon/validate'
import { cusName } from '@api/basic/customer'

export default {
  name: 'd2-container-customer-name',
  data () {
    return {
      values: this.value,
      options: [],
      loading: false
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    clearable: {
      type: Boolean,
      default: () => true
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    multiple: {
      type: Boolean,
      default: () => false
    },
    url: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    remoteMethod (val, query) {
      this.loading = true
      cusName(val).then(response => {
        this.loading = false
        if (response.code === 0) {
          this.options = response.data
        }
      }).catch(() => {
        this.loading = false
      })
    },
    handleSelect (val) {
      this.$emit('change', val)
    }
  },
  watch: {
    values: function (val, oldVal) {
      this.$emit('input', val.toString())
    },
    value: function (val, oldVal) {
      this.values = validatenull(val) ? '' : val
      this.remoteMethod(this.values)
      if (validatenull(this.values)) {
        this.$emit('change', '')
      }
    }
  },
  mounted () {
    this.remoteMethod(this.value)
  }
}
</script>

<style lang="scss" scoped>
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-customer-name/index.vue"
}
</vue-filename-injector>
