import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/basic/dtcar/page',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/basic/dtcar',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/basic/dtcar/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/basic/dtcar/' + id,
    method: 'delete'
  })
}

export function putObj (obj) {
  return request({
    url: '/basic/dtcar',
    method: 'put',
    data: obj
  })
}
/**
 * 车辆档案 批量删除
 */
export function delObjs (ids) {
  return request({
    url: '/basic/dtcar/delbyids',
    method: 'post',
    params: ids
  })
}
/**
 * 设为黑名单
 */
export function isWhiteObjs (ids) {
  return request({
    url: '/basic/dtcar/lockenable',
    method: 'post',
    params: ids
  })
}
/**
 * 设为白名单
 */
export function isBlackObjs (ids) {
  return request({
    url: '/basic/dtcar/lockstop',
    method: 'post',
    params: ids
  })
}
/**
 * 车牌号 判断是否重复
 */
export function getDetails (obj) {
  return request({
    url: '/basic/dtcar/details/' + obj,
    method: 'get'
  })
}
/**
 * 通过搜索 得到车牌号名称
 */
export function getcarinfoName (search) {
  return request({
    url: '/basic/dtcar/cosname',
    method: 'get',
    params: { search: search }
  })
}
/**
 * 定位 通过车牌号获取返回数据
 */
export function getGpsPosition (obj) {
  return request({
    url: '/basic/gps/pos',
    method: 'get',
    params: { carNo: obj }
  })
}
/**
 * 定位 通过车牌号获取返回数据
 */
export function getGpsTrack (carNo, startDate, endDate) {
  return request({
    url: '/basic/gps/locus',
    method: 'get',
    params: { carNo: carNo, startDate: startDate, endDate: endDate }
  })
}
/**
 * 车辆认证 获取信息
 */
export function getDriverMsg (obj) {
  return request({
    url: '/basic/auth/vehicle/driver/details',
    method: 'get',
    params: { phoneNo: obj }
  })
}
