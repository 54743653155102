import request from '@/plugin/axios'
/**
 * 通用文字识别（高精度版）
 */
export function sample (imageUrl) {
  return request({
    url: '/platform/baidu/sample',
    method: 'post',
    params: { imageUrl: imageUrl }
  })
}

export function qrCode (imageUrl) {
  return request({
    url: '/platform/baidu/qrcode',
    method: 'post',
    params: { imageUrl: imageUrl }
  })
}
