import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/basic/oil/page',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/basic/oil',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/basic/oilcard/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/basic/oil/' + id,
    method: 'delete'
  })
}

export function putObj (obj) {
  return request({
    url: '/basic/oil',
    method: 'put',
    data: obj
  })
}

/**
 *  批量删除
 */
export function delObjs (ids) {
  return request({
    url: '/basic/oil/delids',
    method: 'post',
    params: ids
  })
}
/**
 * 油卡管理 银行转账 获取油卡账户列表
 */
export function getOilcardList () {
  return request({
    url: '/basic/oilcard/list',
    method: 'get'
  })
}
/**
 * 判断主卡卡号是否存在
 */
export function getMasterAccount (obj) {
  return request({
    url: '/basic/oil/masterCard',
    method: 'get',
    params: { account: obj }
  })
}
export function getParentAccount (obj, accId) {
  return request({
    url: '/basic/oil/getParentCardId',
    method: 'get',
    params: { account: obj, accId: accId }
  })
}
/**
 * 查询明细
 */
export function getHistoryDetails (query) {
  return request({
    url: '/basic/oil/historyDetails',
    method: 'post',
    data: query
  })
}
