<template>
  <div class="amap-page-container"
       v-loading="loadMarking">
    <div :style="{width:'100%',height:'100%'}">
      <el-amap vid="amapDemo" class="amap-demo" :center="center" :zoom="zoom" :amap-manager="amapManager" :events="events">
        <div v-if="this.positionType === 'carPosition'">
          <div v-for="(item, index) in markers" :key="index">
            <el-amap-marker vid="component-marker" :position="item.position" :events="item.events" :label="{ content: item.content, offset: item.offset }"></el-amap-marker>
          </div>
          <el-amap-info-window v-if="window" :position="window.position" :visible="visible" :content="window.content" :offset="window.offset"></el-amap-info-window>
        </div>
        <div v-else-if="this.positionType === 'carTrack'">
          <div v-for="(item, index) in trackMarkers" :key="index">
            <el-amap-marker vid="polyline-marker" :position="item.position" :events="item.events" :title="carNo" :label="{ content: (index === 0 ? '起' : '终') + '（' + item.content + '）', offset: item.offset }"></el-amap-marker>
          </div>
          <el-amap-polyline :editable="polyline.editable"  :path="polyline.path" :events="polyline.events"></el-amap-polyline>
        </div>
      </el-amap>
    </div>
    <div class="toolbar">
        <span v-if="loaded">
          <el-tag size="" type="danger">经纬度：</el-tag>
          <el-tag size="" type="info">经度</el-tag><span style="font-size: 14px;">= {{ lng }}</span>
          <el-tag size="" type="info">纬度</el-tag><span style="font-size: 14px;">= {{ lat }}</span>
        </span>
        <div v-if="loadedTrack">
          <div v-for="(item, index) in markers" :key="index">
            <el-tag size="" type="danger">{{index === 0 ? '起点：' : '终点：'}}</el-tag>
            <el-tag size="" type="info">时间</el-tag><span style="font-size: 14px;">= {{ item.time }}</span>
            <el-tag size="" type="info">经度</el-tag><span style="font-size: 14px;">= {{ item.lon }}</span>
            <el-tag size="" type="info">纬度</el-tag><span style="font-size: 14px;">= {{ item.lat }}</span>
          </div>
      </div>
      <span v-else></span>
    </div>
  </div>
</template>
<script>

import { getGpsPosition, getGpsTrack } from '@/api/basic/position'
import { AMapManager, lazyAMapApiLoaderInstance } from 'vue-amap'
import { validatenull } from '@/tdcommon/validate'
let amapManager = new AMapManager() // 新建生成地图画布
export default {
  name: 'd2-container-golde-location',
  data () {
    let self = this
    return {
      lng: 0,
      lat: 0,
      adr: '',
      loaded: false,
      loadedTrack: false,
      zoom: 9,
      center: [121.59996, 31.197646],
      amapManager,
      positionType: '', // 定位类型（定位或者轨迹）
      position: [121.59996, 31.197646],
      windows: [],
      window: '',
      visible: false,
      currentWindow: {
        position: [0, 0],
        content: '',
        offset: [5, -15],
        events: {
          close () {
            self.currentWindow.visible = false
          }
        },
        visible: false
      },
      marker: {
        events: {
          click (e) {
            const { lng, lat } = e.lnglat
            self.lng = lng
            self.lat = lat
            // this.slotWindow.position = [this.lng, this.lat]
            // self.currentWindow.position = [this.lng, this.lat]
            // self.currentWindow.content = '地址：' + self.adr
            self.currentWindow.visible = true
          }
        }
      },
      markers: [],
      trackMarkers: [],
      carNo: '',
      polyline: {
        path: [],
        events: {
          click (e) {
            // alert('click polyline')
            this.polyline.editable = true
          },
          end: (e) => {
            // e.target.getPath().map(point => [point.lng, point.lat])
          }
        },
        editable: false,
        loadMarking: false
      },
      events: {
        click (e) {
          const { lng, lat } = e.lnglat
          self.lng = lng
          self.lat = lat
        },
        init (o) {
          lazyAMapApiLoaderInstance.load().then(() => {
            self.initPage() // 初始化数据self指向this
            // self.changeMap() // 绑定鼠标缩放事件
            // self.setLine() // 画一条北京到莫斯科的路线
          })
        }
      }
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    }
  },
  mounted () {
    // this.getInit()
    // this.point()
  },
  methods: {
    initPage () {},
    getCarTrackMsg (carNo, startDate, endDate) {
      let self = this
      self.loadMarking = true
      self.positionType = 'carTrack' // 类型设为轨迹
      getGpsTrack(carNo, startDate, endDate).then(response => {
        if (response.code === 0) {
          if (!validatenull(response.data)) {
            this.carNo = carNo
            // 轨迹
            let itemMsg = []
            // 点
            let markers = []
            // 信息窗体
            // let windows = []

            // let self = this
            response.data.forEach((item, index) => {
              // 轨迹
              let option = []
              option.push(Number(item.Lon).toFixed(6), Number(item.Lat).toFixed(6))
              itemMsg.push(option)
              // 点
              markers.push({
                position: [Number(item.Lon).toFixed(6), Number(item.Lat).toFixed(6)],
                // icon: item.url
                // events: {
                //   click () {
                //     self.windows.forEach(window => {
                //       window.visible = false // 关闭窗体
                //     })
                //
                //     self.window = self.windows[index]
                //     self.$nextTick(() => {
                //       self.window.visible = true // 点击点坐标，出现信息窗体
                //     })
                //   }
                // },
                time: item.GPSTime,
                lon: Number(item.Lon).toFixed(6),
                lat: Number(item.Lat).toFixed(6),
                content: '时间：' + item.GPSTime + '； 经度：' + Number(item.Lon).toFixed(6) + '； 纬度：' + Number(item.Lat).toFixed(6),
                // text: '终点',
                offset: [25, 5]
              })
              // 信息窗体
              // windows.push({
              //   position: [(Number(item.lon) / 600000).toFixed(6), (Number(item.lat) / 600000).toFixed(6)],
              //   content: ' 经度：' + (Number(item.lon) / 600000).toFixed(6) + ' 纬度：' + (Number(item.lat) / 600000).toFixed(6),
              //   visible: false // 初始是否显示
              // })
            })
            // 加轨迹
            this.polyline.path = itemMsg
            //  加点
            // this.markers = markers
            this.trackMarkers = markers.slice(0, 1).concat(markers.slice(-1)) // 轨迹点取首尾两点
          } else {
            this.polyline.path = []
            this.trackMarkers = []
            this.$message.error('暂无轨迹数据！')
          }
        } else {
          self.polyline.path = []
          self.trackMarkers = []
          self.$message.error(response.msg)
        }
        self.loadedTrack = true
        self.loaded = false
        // 加弹窗
        // self.windows = windows
        self.loadMarking = false
      }).catch(() => {
        self.loadMarking = false
      })
    },
    getCarPositionMsg (carno, type) {
      let self = this
      self.positionType = 'carPosition' // 类型设为定位
      if (validatenull(carno)) {
        self.center = [121.59996, 31.197646]
        self.markers = []
        self.window = ''
        if (type !== 'EquipCode') {
          self.$emit('getEquipCodePosition')
        }
        return ''
      } else {
        self.loadMarking = true
        getGpsPosition(carno).then(response => { // 豫FB5520
          if (response.code === 0) {
            // 点
            let markers = []
            // 信息窗体
            let windows = []
            let _this = this
            response.data.forEach((item, index) => {
              if (index === 0) {
                self.center = [Number(item.Lon).toFixed(6), Number(item.Lat).toFixed(6)]
              }
              // 点
              markers.push({
                position: [Number(item.Lon).toFixed(6), Number(item.Lat).toFixed(6)],
                // icon: item.url
                events: {
                  click () {
                    _this.windows.forEach(window => {
                      _this.visible = false // 关闭窗体
                    })

                    _this.window = _this.windows[index]
                    _this.$nextTick(() => {
                      _this.visible = true // 点击点坐标，出现信息窗体
                    })
                  }
                },
                time: item.GPSTime,
                lon: Number(item.Lon).toFixed(6),
                lat: Number(item.Lat).toFixed(6),
                content: item.Vehicle + '&nbsp;&nbsp;' + item.GPSTime.substring(10) + '&nbsp;&nbsp;' + item.Speed + 'km/h&nbsp;&nbsp;T1:' + item.T1 + '℃&nbsp;&nbsp;T2:' + item.T2 + '℃',
                // text: '终点',
                offset: [25, 5]
              })
              // 信息窗体
              windows.push({
                position: [Number(item.Lon).toFixed(6), Number(item.Lat).toFixed(6)],
                content: `<div style="height: 200px;"><div>${item.Vehicle}</div><div>速度：${item.Speed} km/h</div><div>里程：${item.Odometer} km</div><div>定位时间：${item.GPSTime}</div><div>位置：${item.PlaceName + item.RoadName + item.AreaName}</div><div>温度1：${item.T1} ℃</div><div>温度2：${item.T2} ℃</div><div>温度3：${item.T3} ℃</div><div>温度4：${item.T4} ℃</div><div>湿度1：${item.H1} %RH</div><div>湿度2：${item.H2} %RH</div><div>湿度3：${item.H3} %RH</div><div>湿度4：${item.H4} %RH</div></div>`,
                offset: [0, -30]
              })
            })
            self.markers = markers
            // 加弹窗
            self.windows = windows
            // let resObj = JSON.parse(response.data)
            // self.lng = (Number(resObj.lon) / 600000).toFixed(6)
            // self.lat = (Number(resObj.lat) / 600000).toFixed(6)
            // self.adr = resObj.adr
            // self.center = [self.lng, self.lat]
            // self.position = [self.lng, self.lat]
            // self.currentWindow.position = [self.lng, self.lat]
            // self.currentWindow.content = '地址：' + self.adr
            self.loaded = true
            self.loadedTrack = false
            // self.$nextTick(() => {
            //   self.currentWindow.visible = true
            // })
          } else {
            self.center = [121.59996, 31.197646]
            self.markers = []
            self.window = ''
            if (type !== 'EquipCode') {
              self.$emit('getEquipCodePosition')
            }
          }
          self.loadMarking = false
        }).catch(() => {
          self.loadMarking = false
        })
      }
    }
  },
  watch: {
  }
}
</script>

<style lang="scss">
  .amap-demo {
    height: 300px;
  }
  .toolbar {
    margin-top: 10px;
  }
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-golde-location/index.vue"
}
</vue-filename-injector>
