import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/basic/customer/page',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/basic/customer',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/basic/customer/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/basic/customer/' + id,
    method: 'delete'
  })
}

export function putObj (obj) {
  return request({
    url: '/basic/customer',
    method: 'put',
    data: obj
  })
}

/**
 * 发货单位 批量删除
 */
export function delObjs (ids) {
  return request({
    url: '/basic/customer/delbyids',
    method: 'post',
    params: ids
  })
}
/**
 * 通过输入客户名称远程搜索客户信息
 * @param obj 输入搜索信息
 * @returns {AxiosPromise}
 */
export function cusName (custname) {
  return request({
    url: '/basic/customer/getcust',
    method: 'get',
    params: { custname: custname }
  })
}
/**
 * 通过输入客户名称远程搜索客户信息（停用的也能搜索）
 * @param obj 输入搜索信息
 * @returns {AxiosPromise}
 */
export function cusAllName (custname) {
  return request({
    url: '/basic/customer/getcustall',
    method: 'get',
    params: { custname: custname }
  })
}
/**
 * 发货单位 启用
 */
export function lockenableObjs (ids) {
  return request({
    url: '/basic/customer/lock/open',
    method: 'post',
    params: ids
  })
}
/**
 * 发货单位 停用
 */
export function lockstopObjs (ids) {
  return request({
    url: '/basic/customer/lock/stop',
    method: 'post',
    params: ids
  })
}
/**
 * 发货单位 判断是否重复
 */
export function getDetails (obj) {
  return request({
    url: '/basic/customer/details/' + obj,
    method: 'get'
  })
}
/**
 * 营运单导入
 */
export function excelImport (file, id) {
  return request({
    url: '/basic/customer/import/yyd',
    method: 'post',
    params: { file: file, id: id }
  })
}
/**
 * 营运单导入 返回结果
 */
export function returnImport () {
  return request({
    url: '/basic/customer/import/progress',
    method: 'get'
  })
}
/**
 * 托运单导入
 */
export function excelImportTyd (file, id) {
  return request({
    url: '/basic/customer/import/tyd',
    method: 'post',
    params: { file: file, id: id }
  })
}
/**
 * 上传阿里 获取签名
 */
export function uploadAli () {
  return request({
    url: '/tms/ossobject/sign',
    method: 'get'
  })
}
/**
 * 上传阿里 客户资料保存到后台
 */
export function addUploadAli (obj) {
  return request({
    url: '/tms/ossobject/upload',
    method: 'post',
    data: obj
  })
}
/**
 * 上传阿里 客户资料返回数据接口
 */
export function getOssCusList (obj) {
  return request({
    url: '/tms/ossobject/list',
    method: 'get',
    params: { objectNo: obj }
  })
}
/**
 * 删除上传阿里的后台数据
 */
export function delOssData (id) {
  return request({
    url: '/tms/ossobject/' + id,
    method: 'delete'
  })
}
/**
 * 通过ids进行财务审核
 */
export function cusAudit (ids) {
  return request({
    url: '/tms/customer/audit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行财务反审核
 */
export function cusUnaudit (ids) {
  return request({
    url: '/tms/customer/unaudit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行终审
 */
export function cusExamine (ids) {
  return request({
    url: '/tms/customer/examine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行反终审
 */
export function cusUnexamine (ids) {
  return request({
    url: '/tms/customer/unexamine',
    method: 'post',
    params: ids
  })
}
/**
 * 上传阿里 批量下载
 */
export function aliBatchDownload (obj) {
  return request({
    url: '/tms/ossobject/aliBatchDownload',
    method: 'post',
    responseType: 'blob',
    params: obj
  })
}
/**
 * 上传阿里 通过itemType区分 获取返回数据接口
 */
export function getOssObjectList (obj) {
  return request({
    url: '/tms/ossobject/itemType',
    method: 'get',
    params: obj
  })
}
/**
 * 上传阿里 客户合同 保存到后台
 */
export function uploadAliAberrant (obj) {
  return request({
    url: '/tms/ossobject/projectManagementAberrant',
    method: 'post',
    data: obj
  })
}
/**
 * 上传阿里 客户资质 保存到后台
 */
export function uploadAliQualifications (obj) {
  return request({
    url: '/tms/ossobject/customerQualifications',
    method: 'post',
    data: obj
  })
}
/**
 * 上传阿里 确认函 保存到后台
 */
export function uploadAliConfirmationLetter (obj) {
  return request({
    url: '/tms/ossobject/confirmationLetter',
    method: 'post',
    data: obj
  })
}
/**
 * 上传阿里 往来资料 保存到后台
 */
export function uploadAliCurrentInformation (obj) {
  return request({
    url: '/tms/ossobject/currentInformation',
    method: 'post',
    data: obj
  })
}
/**
 * 通过ids进行 启用逾期风控
 */
export function enableCostRisk (ids) {
  return request({
    url: '/tms/customer/EnableCostRisk',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 停用逾期风控
 */
export function stopCostRisk (ids) {
  return request({
    url: '/tms/customer/stopCostRisk',
    method: 'post',
    params: ids
  })
}
/**
 * 通过输入客户名称远程搜索客户信息
 * @param obj 输入搜索信息
 * @returns {AxiosPromise}
 */
export function getProjectName (custname) {
  return request({
    url: '/basic/customer/getproject',
    method: 'get',
    params: { custname: custname }
  })
}

export function newAddCus () {
  return request({
    url: '/basic/customer/newAddCus',
    method: 'get'
  })
}
