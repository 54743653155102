import request from '@/plugin/axios'

/*
* 分页查询*
*/
export function fetchList (query) {
  return request({
    url: '/bms/accsettle/page',
    method: 'post',
    data: query
  })
}
/*
* 新增财务收款付款表*
*/
export function addObj (obj) {
  return request({
    url: '/bms/accsettle',
    method: 'post',
    data: obj
  })
}
/*
* 通过id查询*
*/
export function getEditObj (id) {
  return request({
    url: '/bms/accsettle/' + id,
    method: 'get'
  })
}
/**
 * 修改 财务收款付款表
 */
export function putObj (obj) {
  return request({
    url: '/bms/accsettle',
    method: 'put',
    data: obj
  })
}
/**
 * 通过ids批量删除运单
 */
export function sfkdelObjs (ids) {
  return request({
    url: '/bms/accsettle/dels',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行审单
 */
export function sfkAudit (ids) {
  return request({
    url: '/bms/accsettle/auditdoc',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行反审单
 */
export function sfkUnaudit (ids) {
  return request({
    url: '/bms/accsettle/auditdocundo',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行审核
 */
export function sfkExamine (ids) {
  return request({
    url: '/bms/accsettle/audit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行反审核
 */
export function sfkUnexamine (ids) {
  return request({
    url: '/bms/accsettle/auditundo',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行核销
 */
export function sfkcancell (ids) {
  return request({
    url: '/bms/accsettle/verify',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行反核销
 */
export function sfkUncancell (ids) {
  return request({
    url: '/bms/accsettle/verifyundo',
    method: 'post',
    params: ids
  })
}
/*
* 通过id收付款凭证红冲*
*/
export function sfkReverse (id) {
  return request({
    url: '/bms/accsettle/reverse',
    method: 'post',
    params: { id: id }
  })
}
/**
 * 通过付款方式 获取对应银行账户
 */
export function getAccountType (obj) {
  return request({
    url: '/bms/accdtaccount/filter/' + obj,
    method: 'get'
  })
}
/**
 * 上传阿里 收款付款保存到后台
 */
export function addUploadAliSettle (obj) {
  return request({
    url: '/tms/ossobject/settle',
    method: 'post',
    data: obj
  })
}
/**
 * 二级表导出*
 */
// export function secondFloorExport (obj) {
//   return request({
//     url: '/tms/accsettle/secondFloorExport',
//     method: 'post',
//     responseType: 'blob',
//     data: obj
//   })
// }
export function secondFloorExport (obj) {
  return request({
    url: '/ex/export/save',
    method: 'post',
    responseType: 'blob',
    data: obj
  })
}
/**
 * 三级表导出*
 */
// export function thirdFloorExport (obj) {
//   return request({
//     url: '/tms/accsettle/threeFloorExport',
//     method: 'post',
//     responseType: 'blob',
//     data: obj
//   })
// }
export function thirdFloorExport (obj) {
  return request({
    url: '/ex/export/threefloorexport',
    method: 'post',
    responseType: 'blob',
    data: obj
  })
}
/**
 * 郑明导出*
 */
// export function zmFinancialExport (obj) {
//   return request({
//     url: '/tms/accsettle/zmExport',
//     method: 'post',
//     responseType: 'blob',
//     data: obj
//   })
// }
export function zmFinancialExport (obj) {
  return request({
    url: '/ex/export/zmexport',
    method: 'post',
    responseType: 'blob',
    data: obj
  })
}
/**
 * 老吕加油、万金油导出*
 */
export function oilcardExport (obj) {
  return request({
    url: '/bms/accsettle/panaceaExportDetails',
    method: 'post',
    responseType: 'blob',
    data: obj
  })
}
/*
* 查看二级表*
*/
export function secondTable (query) {
  return request({
    url: '/bms/accsettlejz/page',
    method: 'post',
    data: query
  })
}
/*
* 查看三级表*
*/
export function thirdTable (query) {
  return request({
    url: '/bms/accsettledoc/page',
    method: 'post',
    data: query
  })
}
/*
* 通过id查询*
*/
export function getPayStatus () {
  return request({
    url: '/bms/accsettle/getPayStatus',
    method: 'get'
  })
}
