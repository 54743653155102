import request from '@/plugin/axios'
// 主表分页查询
export function fetchList (query) {
  return request({
    url: '/basic/project/price/page',
    method: 'post',
    data: query
  })
}
// 新增保存
export function addObj (obj) {
  return request({
    url: '/basic/project/price',
    method: 'post',
    data: obj
  })
}
// 通过ids删除主表
export function delObj (ids) {
  return request({
    url: '/basic/project/price',
    method: 'delete',
    params: ids
  })
}
/**
 * 通过ids批量删除运单
 */
export function batchDelObjs (ids) {
  return request({
    url: '/basic/project/price',
    method: 'delete',
    params: ids
  })
}
// 修改保存
export function putObj (obj) {
  return request({
    url: '/basic/project/price',
    method: 'put',
    data: obj
  })
}

/*
* 通过id查询*
*/
export function tredit (id) {
  return request({
    url: '/basic/project/price/' + id,
    method: 'get'
  })
}

/**
 * 通过ids进行 初审
 */
export function trAudit (ids) {
  return request({
    url: '/basic/project/price/auditdoc',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 反初审
 */
export function trUnaudit (ids) {
  return request({
    url: '/basic/project/price/auditdocundo',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 复审
 */
export function trExamine (ids) {
  return request({
    url: '/basic/project/price/audit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 反复审
 */
export function trUnexamine (ids) {
  return request({
    url: '/basic/project/price/auditundo',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 启用/停用
 */
export function priceLock (ids, type) {
  return request({
    url: '/basic/project/price/lock',
    method: 'get',
    params: { ids: ids, type: type }
  })
}
/**
 * 导入 返回结果
 */
export function returnImportPriceCus () {
  return request({
    url: '/basic/project/price/progress',
    method: 'get'
  })
}
// 托运单 自动计费 调用运价
export function tmsydGetPrice (obj) {
  return request({
    url: '/basic/project/price/tmsyd',
    method: 'post',
    data: obj
  })
}
/**
 * 项目运价表- 展开获取明细
 */
export function getDetails (priceId, routeId) {
  return request({
    url: '/basic/project/price/details',
    method: 'get',
    params: { priceId: priceId, routeId: routeId }
  })
}

export function priceByCftthc (senderId, transportMode, transportProperty, fromCity, destCity, goodsName) {
  return request({
    url: '/basic/project/price/priceByCftthc',
    method: 'post',
    data: { senderId: senderId, transportMode: transportMode, transportProperty: transportProperty, fromCity: fromCity, destCity: destCity, goodsName: goodsName }
  })
}
