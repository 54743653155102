<template>
  <div class="webCamDiv" v-loading="webCamLoading" :element-loading-text="loadingText" :element-loading-spinner="loadingSpinner">
    <el-empty :image="`${$baseUrl}image/icons/noWebCam.png`" :image-size="200" :description="errorDescription" v-show="isStatus">
      <el-button type="primary" @click="fetchCamList">重载设备</el-button>
    </el-empty>
    <div class="videoDiv">
      <div class="cameraTitle">
        <el-dropdown split-button type="primary" trigger="click" @command="changerWebcam">
          切换设备
          <el-dropdown-menu slot="dropdown">
            <template v-for="item in devices">
              <el-dropdown-item :key="item.deviceId" :command="item.deviceId"><el-avatar :src="`${$baseUrl}image/icons/webCamIcon.png`" :size="17" style="margin-right: 5px;margin-top: 2px;"></el-avatar>{{item.label}}</el-dropdown-item>
            </template>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <video id="videoMark" autoplay="autoplay" width="800px" height="600px"></video>
      <canvas id="canvasMark" width="1200" height="1100" style="display: none"></canvas>
    </div>
  </div>
</template>
<script>
import { validatenull } from '@/tdcommon/validate'
import { sample } from '@/api/basic/baiduocr'
import { dtaURItoBlob } from '@/tdcommon/beeutil'
import dayjs from 'dayjs'
import { uploadAli } from '@/api/basic/customer'
import axios from 'axios'

export default {
  name: 'd2-container-webcam',
  data () {
    return {
      // 设备接入状态
      isStatus: true,
      // loading-text,
      loadingText: '加载设备中',
      // loading-spinner
      loadingSpinner: 'el-icon-loading',
      // 设备接入错误
      errorDescription: '请检查高拍仪是否已正常接入设备',
      // 当前设备对象
      promise: null,
      // 设备加载状态
      webCamLoading: false,
      // 播放器
      videoObj: null,
      // canvas静像容器
      canvasObj: null,
      // USB摄像头列表
      devices: [],
      // 默认加载设备配置
      constraints: {
        video: { width: 4032, height: 3024 },
        audio: false
      }
    }
  },
  props: {
  },
  methods: {
    // 打开设备及播放器信息
    openCam (constraints) {
      var self = this
      self.webCamLoading = true
      // 播放器
      self.videoObj = document.getElementById('videoMark')
      // canvas静像容器
      self.canvasObj = document.getElementById('canvasMark')
      if (navigator.mediaDevices.getUserMedia) {
        // 最新的标准API
        self.promise = navigator.mediaDevices.getUserMedia(constraints)
      } else if (navigator.webkitGetUserMedia) {
        // webkit核心浏览器
        self.promise = navigator.webkitGetUserMedia(constraints)
      } else if (navigator.mozGetUserMedia) {
        // firfox浏览器
        self.promise = navigator.mozGetUserMedia(constraints)
      } else if (navigator.getUserMedia) {
        // 旧版API
        self.promise = navigator.getUserMedia(constraints)
      }
      self.promise.then(function (MediaStream) {
        self.webCamLoading = false
        self.videoObj.srcObject = MediaStream
        self.videoObj.play()
        self.isStatus = false
        self.errorDescription = '设备接入成功'
      }).catch(function (PermissionDeniedError) {
        self.isStatus = false
        self.webCamLoading = false
        if (PermissionDeniedError.name === 'NotFoundError') {
          self.errorDescription = '请确认相关设备已连接电脑！'
        } else {
          self.errorDescription = '请检查相关设备是否可以正常工作！'
        }
      })
    },
    // 取得设备列表
    fetchCamList () {
      var self = this
      navigator.mediaDevices.enumerateDevices().then(devices => {
        devices.forEach(item => {
          if (!validatenull(item.deviceId) && item.kind === 'videoinput') {
            let itemObj = { deviceId: item.deviceId }
            if (validatenull(item.label)) {
              itemObj.label = `摄像设备(${item.deviceId})`
            } else {
              itemObj.label = item.label
            }
            self.devices.push(itemObj)
          }
        })
      })
    },
    // 关闭设备及播放器
    closeCam () {
      if (this.videoObj.srcObject) {
        let stream = this.videoObj.srcObject
        let tracks = stream.getTracks()
        tracks.forEach(track => {
          track.stop()
        })
      }
    },
    // 播放器生成记录到canvasId
    sapnDrawImage () {
      if (this.canvasObj !== null && this.videoObj !== null) {
        let ctx = this.canvasObj.getContext('2d')
        // var ratio = this.getPixelPrecision(ctx)
        // ctx.drawImage(this.videoObj, 0, 0, 2989*ratio, 2518*ratio);
        ctx.drawImage(this.videoObj, 0, 0, 1200, 1100)
        return this.canvasObj.toDataURL('image/png', 1.0)
      } else {
        this.$message.error('请检查设备是否正常！')
        return null
      }
    },
    // 自适应像素处理
    getPixelPrecision (context) {
      var backingStore = context.backingStorePixelRatio ||
          context.webkitBackingStorePixelRatio ||
          context.mozBackingStorePixelRatio ||
          context.msBackingStorePixelRatio ||
          context.oBackingStorePixelRatio ||
          context.backingStorePixelRatio || 1
      return (window.devicePixelRatio || 1) / backingStore
    },
    // 切换设备
    changerWebcam (val) {
      const constraints = {
        video: { width: 4032, height: 3024, deviceId: { exact: val } },
        audio: false
      }
      this.openCam(constraints)
    },
    // 识别扫描摄像图片
    discriminantCams () {
      this.loadingText = '百度识别中，请稍后'
      this.webCamLoading = true
      var self = this
      // 取得图片Basic64
      var ImageBasic = this.sapnDrawImage()
      if (!validatenull(ImageBasic)) {
        self.loadingText = '生成图片信息，请稍后'
        let blob = dtaURItoBlob(ImageBasic)
        blob.lastModifiedDate = new Date()
        blob.name = 'webCams_' + dayjs(new Date()).format('YYYYMMDDHHmmssSSS') + Math.random().toString().substr(2, 4) + '.png'
        var fd = { name: blob.name, percentage: 0, raw: blob, size: blob.size, status: 'ready' }
        self.loadingText = '上传图片到服务中心，请稍后'
        this.updateAlianOss().then(response => {
          if (response.code === 0) {
            var fileName = dayjs(new Date()).format('YYYYMMDDHHmmss') + Math.random().toString().substr(2, 3) + fd.raw.name // 上传阿里保存 文件名=日期+3位随机码+原有文件名
            let keyValue = response.data.dir + fileName
            self.loadingText = '检验图片完整，请稍后'
            this.postAliImage(response.data, fd, fileName, keyValue).then(res => {
              if (res.status === 200) {
                var imageUrl = res.config.url + '/' + keyValue
                self.loadingText = '服务中心在提取信息，请稍后'
                sample(imageUrl).then(res => {
                  if (res.code === 0) {
                    self.loadingText = '识别成功，加载信息中'
                    this.$emit('WebCamSance', { imageUrl: imageUrl, textVal: res.data })
                    self.webCamLoading = false
                  } else {
                    self.$message.error(res.msg)
                    self.webCamLoading = false
                    self.loadingText = '加载设备中'
                  }
                }).catch(() => {
                  self.webCamLoading = false
                  self.loadingText = '加载设备中'
                })
              } else {
                self.$message.error(res.statusText)
                self.webCamLoading = false
                self.loadingText = '加载设备中'
              }
            }).catch(() => {
              this.webCamLoading = false
              this.loadingText = '加载设备中'
            })
          } else {
            self.$message.error(response.msg)
            self.webCamLoading = false
            self.loadingText = '加载设备中'
          }
        }).catch(() => {
          self.webCamLoading = false
          self.loadingText = '加载设备中'
        })
      } else {
        self.webCamLoading = false
        self.loadingText = '加载设备中'
      }
    },
    // 阿里OSS签名
    updateAlianOss () {
      return uploadAli()
    },
    // 上传图片到阿里OSS
    postAliImage (data, file, fileName, keyValue) {
      var ossData = new FormData()
      // let fileNamePoint = file.raw.name.lastIndexOf('.') // 取到文件名开始到最后一个点的长度
      // let fileNameLength = file.raw.name.length // 取到文件名长度
      // let imgType = file.raw.name.substring(fileNamePoint + 1, fileNameLength).toLocaleLowerCase() // 截  所有后缀格式转小写
      ossData.append('name', fileName)
      ossData.append('key', keyValue)
      ossData.append('policy', data.policy)
      ossData.append('OSSAccessKeyId', data.accessid)
      ossData.append('success_action_status', 200)
      ossData.append('signature', data.signature)
      ossData.append('callback', data.callback)
      ossData.append('file', file.raw, file.raw.name)
      return axios.post(data.host, ossData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    }
  },
  watch: {
  },
  created () {
  },
  mounted () {
    this.openCam(this.constraints)
    this.fetchCamList()
  },
  destroyed () {
    this.closeCam()
  }
}
</script>

<style lang="scss" scoped>
.webCamDiv {
  width: 100%;
}
.videoDiv {
  width: 100%;
}
.cameraTitle {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  margin-right: 70px;
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-webcam/index.vue"
}
</vue-filename-injector>
