import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/tms/wdj/page',
    method: 'post',
    data: query
  })
}
/**
 * 根据成本单号查询温控数据
 */
export function saveThermometer (obj) {
  return request({
    url: '/tms/wdj',
    method: 'post',
    data: obj
  })
}
/**
 * 根据成本单号查询温控数据
 */
export function updateThermometer (obj) {
  return request({
    url: '/tms/wdj',
    method: 'put',
    data: obj
  })
}
/**
 * 根据成本单号查询温控数据
 */
export function deleteThermometer (ids) {
  return request({
    url: '/tms/wdj/delids',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 入库
 */
export function entryMsg (obj) {
  return request({
    url: '/tms/wdj/entry',
    method: 'post',
    data: obj
  })
}
/**
 * 通过ids进行 扫码入库
 */
export function entryScan (wdjNo, type) {
  return request({
    url: '/tms/wdj/entryScan',
    method: 'post',
    params: { wdjNo: wdjNo, type: type }
  })
}
/**
 * 通过ids进行 撤销入库
 */
export function entryUndo (obj) {
  return request({
    url: '/tms/wdj/entryUndo',
    method: 'post',
    data: obj
  })
}

/**
 * 通过ids进行 返还
 */
export function returnMsg (obj) {
  return request({
    url: '/tms/wdj/return',
    method: 'post',
    data: obj
  })
}
/**
 * 通过ids进行 撤销返还
 */
export function returnUndo (obj) {
  return request({
    url: '/tms/wdj/returnUndo',
    method: 'post',
    data: obj
  })
}
/**
 * 根据成本单号查询温控数据
 */
export function getTemperature (docNo) {
  return request({
    url: '/platform/temperature/queryByDoc',
    method: 'get',
    params: { docNo: docNo }
  })
}
/**
 * 温度计明细分页接口
 */
export function getWdjDetails (query) {
  return request({
    url: '/tms/wdjdetails/page',
    method: 'post',
    data: query
  })
}
/**
 * 下拉获取温度计编号
 */
export function getWdjNo (wdjNo) {
  return request({
    url: '/tms/wdj/getWdjNo',
    method: 'get',
    params: { wdjNo: wdjNo }
  })
}
