<template>
  <el-select class="td_select"
             v-model="values"
             default-first-option
             filterable
             remote
             reserve-keyword
             allow-create
             placeholder="请输入关键词"
             :clearable="clearable"
             :disabled="disabled"
             :remote-method="remoteMethod"
             @clear="clearAction"
             :loading="loading">
    <el-option
            class="td_option"
            v-for="(item, index) in options"
            :key="item.id + index"
            :label="item.wdjNo"
            :value="item.wdjNo"
            @click.native="handleSelect(item)">
    </el-option>
  </el-select>
</template>
<script>

import { validatenull } from '@/tdcommon/validate'
import { getWdjNo } from '@/api/tms/thermometer'

export default {
  name: 'd2-container-wdjNo',
  data () {
    return {
      values: this.value,
      options: [],
      loading: false
    }
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    remoteMethod (query) {
      this.loading = true
      var searchQuery = validatenull(query) ? '' : query
      getWdjNo(searchQuery).then(response => {
        console.log('getWdjNo', response.data)
        this.loading = false
        if (response.code === 0) {
          this.options = response.data
        }
      }).catch(() => {
        this.loading = false
      })
    },
    handleSelect (val) {
      this.$emit('change', val)
    },
    clearAction () {
      this.$emit('change', '')
    }
  },
  watch: {
    values: function (val, oldVal) {
      this.$emit('input', val)
    },
    value: function (val, oldVal) {
      this.remoteMethod(val)
      this.values = val
    }
  },
  mounted () {
    this.remoteMethod(this.value)
  }
}
</script>

<style lang="scss" scoped>
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-wdjNo/index.vue"
}
</vue-filename-injector>
