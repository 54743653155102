import request from '@/plugin/axios'
/**
 * 分页查询
 */
export function fetchList (query) {
  return request({
    url: '/tms/dtdispatcher/page',
    method: 'post',
    data: query
  })
}
/**
 * 新增
 */
export function addObj (obj) {
  return request({
    url: '/tms/dtdispatcher',
    method: 'post',
    data: obj
  })
}
/**
 * 通过id查询 编辑获取数据
 */
export function getObj (id) {
  return request({
    url: '/tms/dtdispatcher/' + id,
    method: 'get'
  })
}
/**
 * 通过id删除
 */
export function delObj (id) {
  return request({
    url: '/tms/dtdispatcher/' + id,
    method: 'delete'
  })
}
/**
 *
 修改
 */
export function putObj (obj) {
  return request({
    url: '/tms/dtdispatcher',
    method: 'put',
    data: obj
  })
}
/**
 *  批量删除
 */
export function delObjs (ids) {
  return request({
    url: '/tms/dtdispatcher/delbyids',
    method: 'post',
    params: ids
  })
}
/*
*用户管理  获取员工档案的用户名*
*/
export function fetchDispatchName () {
  return request({
    url: '/tms/dtdispatcher/list',
    method: 'get'
  })
}
/**
 * 上传阿里 保存到后台
 */
export function uploadAliDispatch (obj) {
  return request({
    url: '/tms/ossobject/dtDispatcher',
    method: 'post',
    data: obj
  })
}
/**
 * 通过ids进行审单
 */
export function dispatchAudit (ids) {
  return request({
    url: '/tms/dtdispatcher/audit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行反审单
 */
export function dispatchUnAudit (ids) {
  return request({
    url: '/tms/dtdispatcher/unAudit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行审核
 */
export function dispatchExamine (ids) {
  return request({
    url: '/tms/dtdispatcher/examine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行反审核
 */
export function dispatchUnExamine (ids) {
  return request({
    url: '/tms/dtdispatcher/unExamine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行复审
 */
export function dispatchReExamine (ids) {
  return request({
    url: '/tms/dtdispatcher/retrial',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行反复审
 */
export function dispatchUnReExamine (ids) {
  return request({
    url: '/tms/dtdispatcher/unRetrial',
    method: 'post',
    params: ids
  })
}
