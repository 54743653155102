import request from '@/plugin/axios'
/**
 * 收货人管理查询接口
 */
export function fetchList (query) {
  return request({
    url: '/basic/receiver/query',
    method: 'post',
    data: query
  })
}
/**
 * 客户管理 详情页收货方查询接口
 */
export function searchList (query) {
  return request({
    url: '/basic/receiver/page',
    method: 'get',
    params: query
  })
}
export function addObj (obj) {
  return request({
    url: '/basic/receiver',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/basic/receiver/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/basic/receiver/' + id,
    method: 'delete'
  })
}

export function putObj (obj) {
  return request({
    url: '/basic/receiver',
    method: 'put',
    data: obj
  })
}
/**
 * 通过输入收货单位远程搜索客户信息
 * @param obj 输入搜索信息
 * @returns {AxiosPromise}
 */
export function getCustName (custName) {
  return request({
    url: 'basic/customer/getcust',
    method: 'get',
    params: { custname: custName }
  })
}
/**
 * 收货人管理 判断收货单位重复
 */
export function getRepeatObj (obj) {
  return request({
    url: '/basic/receiver/details/' + obj,
    method: 'get'
  })
}
/**
 * 通过输入收货单位远程搜索客户信息
 * @param obj 输入搜索信息
 * @returns {AxiosPromise}
 */
export function getRecName (custId, contName) {
  return request({
    url: '/basic/receiver/list',
    method: 'get',
    params: { id: custId, receiverName: contName }
  })
}
