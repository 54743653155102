<template>
  <div id="app" v-loading="webCamLoading" :element-loading-text="errorDescription" :element-loading-spinner="loadingSpinner">
    <el-empty :image="`${$baseUrl}image/icons/noWebCam.png`" :image-size="200" :description="errorDescription" v-show="isStatus">
      <el-button type="primary" @click="rePayCam">重载设备</el-button>
    </el-empty>
    <div v-show="!isStatus">
      <div class="option-view">
        <el-dropdown split-button type="primary" trigger="click" @command="changerWebcam">
          切换设备
          <el-dropdown-menu slot="dropdown">
            <template v-for="item in devices">
              <el-dropdown-item :key="item.deviceId" :command="item.deviceId"><el-avatar :src="`${$baseUrl}image/icons/webCamIcon.png`" :size="17" style="margin-right: 5px;margin-top: 2px;"></el-avatar>{{item.label}}</el-dropdown-item>
            </template>
          </el-dropdown-menu>
        </el-dropdown>
<!--        <el-button type="danger" @click="onSacem" v-if="!isSelfScan">开始自动识别</el-button>-->
<!--        <el-button type="primary" @click="onColseCode" v-if="isSelfScan">关闭自动识别</el-button>-->
      </div>
      <div>
        <div class="image-div">
          <div class="code-box">
<!--            <span class="outTime-span">{{outTime}}</span>-->
          </div>
          <span class="error-span">{{outMsg}}</span>
        </div>
        <video id="videoMark" autoplay="autoplay" width="800px" height="600px">
        </video>
        <canvas id="canvasMark" width="1200" height="1100" style="display: none"></canvas>
      </div>
    </div>
  </div>
</template>
<script>
import { validatenull } from '@/tdcommon/validate'
import { uploadAli } from '@/api/basic/customer'
import axios from 'axios'
import { dtaURItoBlob } from '@/tdcommon/beeutil'
import dayjs from 'dayjs'
import jsQR from 'jsqr'
export default {
  name: 'd2-container-qrcode',
  components: {
  },
  data () {
    return {
      // 设备加载状态
      webCamLoading: false,
      // 设备接入状态
      isStatus: false,
      // loading-text,
      loadingText: '加载设备中',
      // loading-spinner
      loadingSpinner: 'el-icon-loading',
      // 设备接入错误
      errorDescription: '请检查高拍仪是否已正常接入设备',
      // USB摄像头列表
      devices: [],
      // 默认加载设备配置
      constraints: {
        video: { width: 4032, height: 3024 },
        audio: false
      },
      // 当前设备对象
      promise: null,
      isSelfScan: false,
      outTime: '',
      outMsg: '',
      videoObj: null,
      canvasObj: null
    }
  },
  props: {
  },
  methods: {
    async onSacem () {
      this.isSelfScan = true
      await this.slefScan()
    },
    onColseCode () {
      this.isSelfScan = false
      this.outMsg = ''
      this.outTime = ''
    },
    async slefScan () {
      this.outMsg = ''
      if (this.isSelfScan) {
        this.outTime = '3'
        await this.timeout(1500)
      }
      if (this.isSelfScan) {
        this.outTime = '2'
        await this.timeout(1500)
      }
      if (this.isSelfScan) {
        this.outTime = '1'
        await this.timeout(1500)
      }
      if (this.isSelfScan) {
        this.outTime = ''
        await this.updateImageToOss()
      }
    },
    // 切换设备
    async changerWebcam (val) {
      const constraints = {
        video: { width: 4032, height: 3024, deviceId: { exact: val } },
        audio: false
      }
      await this.changeScam(constraints)
    },
    async changeScam (val) {
      var self = this
      self.webCamLoading = true
      // 播放器
      self.videoObj = document.getElementById('videoMark')
      // canvas静像容器
      self.canvasObj = document.getElementById('canvasMark')
      try {
        if (navigator.mediaDevices.getUserMedia) {
          // 最新的标准API
          self.promise = navigator.mediaDevices.getUserMedia(val)
        } else if (navigator.webkitGetUserMedia) {
          // webkit核心浏览器
          self.promise = navigator.webkitGetUserMedia(val)
        } else if (navigator.mozGetUserMedia) {
          // firfox浏览器
          self.promise = navigator.mozGetUserMedia(val)
        } else if (navigator.getUserMedia) {
          // 旧版API
          self.promise = navigator.getUserMedia(val)
        }
        await self.promise.then(MediaStream => {
          self.errorDescription = '设备接入成功'
          self.videoObj.srcObject = MediaStream
          if (self.videoObj.srcObject.oninactive === null) {
            self.videoObj.srcObject.oninactive = () => self.videoListener()
          }
          self.videoObj.play()
          self.isStatus = false
        })
      } catch (PermissionDeniedError) {
        self.isStatus = true
        if (PermissionDeniedError.name === 'NotAllowedError') {
          this.errorDescription = '您需要授予相机访问权限'
        } else if (PermissionDeniedError.name === 'NotFoundError') {
          this.errorDescription = '这个设备上没有摄像头'
        } else if (PermissionDeniedError.name === 'NotSupportedError') {
          this.errorDescription = '所需的安全上下文(HTTPS、本地主机)'
        } else if (PermissionDeniedError.name === 'NotReadableError') {
          this.errorDescription = '摄像头被占用'
        } else if (PermissionDeniedError.name === 'OverconstrainedError') {
          this.errorDescription = '安装摄像头不合适'
        } else if (PermissionDeniedError.name === 'StreamApiNotSupportedError') {
          this.errorDescription = '此浏览器不支持流API'
        } else if (PermissionDeniedError.name === 'InsecureContextError') {
          this.errorDescription = '扫码引导只能是在https上执行'
        } else if (PermissionDeniedError.name === 'NotFoundError') {
          self.errorDescription.error('请确认相关设备已连接电脑！')
        } else {
          this.errorDescription = `摄像头错误 (${PermissionDeniedError.name})`
        }
      } finally {
        await this.timeout(2000)
        self.webCamLoading = false
      }
    },
    // 监听摄像头是否正常
    videoListener () {
      this.isStatus = true
      this.errorDescription = '设备掉线了，请检查设备是否正常联接电脑！'
    },
    // 取得设备列表
    fetchCamList () {
      var self = this
      navigator.mediaDevices.enumerateDevices().then(devices => {
        devices.forEach(item => {
          if (!validatenull(item.deviceId) && item.kind === 'videoinput') {
            let itemObj = { deviceId: item.deviceId }
            if (validatenull(item.label)) {
              itemObj.label = `摄像设备(${item.deviceId})`
            } else {
              itemObj.label = item.label
            }
            self.devices.push(itemObj)
          }
        })
      })
    },
    // 重置状态
    rePayCode () {
      let self = this
      setTimeout(() => {
        self.videoObj.play()
        self.slefScan()
      }, 1000)
    },
    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    rePayCam () {
      var ImageBasic = this.sapnDrawImage()
      if (!validatenull(ImageBasic)) {
        console.log(ImageBasic)
      }
    },
    // 播放器生成记录到canvasId
    sapnDrawImage () {
      if (this.canvasObj !== null && this.videoObj !== null) {
        let ctx = this.canvasObj.getContext('2d')
        ctx.drawImage(this.videoObj, 0, 0, 1200, 1100)
        return this.canvasObj.toDataURL('image/png', 1.0)
      } else {
        this.$message.error('请检查设备是否正常！')
        return null
      }
    },
    // 取得二维图片Basic64
    codeDrawImage () {
      if (this.canvasObj !== null && this.videoObj !== null) {
        let ctx = this.canvasObj.getContext('2d')
        ctx.drawImage(this.videoObj, 1200, 800, 650, 650, 0, 0, 200, 200)
        return ctx.getImageData(0, 0, 400, 400)
      } else {
        this.$message.error('请检查设备是否正常！')
        return null
      }
    },
    // 上传图片到阿里OSS
    updateImageToOss () {
      let self = this
      this.outMsg = '开始扫描'
      // 暂停设备
      this.videoObj.pause()
      // 取得二维图片Basic64
      var codeBasic = this.codeDrawImage()
      if (codeBasic !== undefined && codeBasic != null) {
        this.outMsg = ''
        const obj = jsQR(codeBasic.data, codeBasic.width, codeBasic.height, {
          inversionAttempts: 'dontInvert'
        })
        if (obj !== undefined && obj != null && obj.data !== undefined && obj.data !== null) {
          this.outMsg = '识别成功，请移开当前回单单据'
          let conantText = obj.data
          var ImageBasic = this.sapnDrawImage()
          this.outMsg = '整理图片，准备上传'
          if (ImageBasic !== undefined && ImageBasic != null) {
            let blob = dtaURItoBlob(ImageBasic)
            blob.lastModifiedDate = new Date()
            blob.name = 'webCams_' + dayjs(new Date()).format('YYYYMMDDHHmmssSSS') + Math.random().toString().substr(2, 4) + '.png'
            var fd = { name: blob.name, percentage: 0, raw: blob, size: blob.size, status: 'ready' }
            this.updateAlianOss().then(response => {
              this.outMsg = '开始上传扫描件'
              if (response.code === 0) {
                var fileName = dayjs(new Date()).format('YYYYMMDDHHmmss') + Math.random().toString().substr(2, 3) + fd.raw.name // 上传阿里保存 文件名=日期+3位随机码+原有文件名
                let keyValue = response.data.dir + fileName
                this.postAliImage(response.data, fd, fileName, keyValue).then(res => {
                  if (res.status === 200) {
                    var imageUrl = res.config.url + '/' + keyValue
                    this.outMsg = '识别完成，请上传相关单据'
                    self.$emit('WebCamSance', { imageUrl: imageUrl, textVal: conantText })
                  }
                })
              }
            })
          }
        } else {
          this.outMsg = '找不到二维码哦！请调整二维码进入扫描区内'
          this.rePayCode()
        }
      } else {
        this.outMsg = '请检查设备是否正常工作'
        this.rePayCode()
      }
    },
    // 手动拍照
    manualPhoto () {
      let self = this
      if (self.isStatus) {
        this.$message.error('设备出错')
        return
      }
      this.outMsg = '开始拍摄照片'
      this.videoObj.pause()
      var ImageBasic = this.sapnDrawImage()
      if (ImageBasic !== undefined && ImageBasic != null) {
        let blob = dtaURItoBlob(ImageBasic)
        blob.lastModifiedDate = new Date()
        blob.name = 'webCams_' + dayjs(new Date()).format('YYYYMMDDHHmmssSSS') + Math.random().toString().substr(2, 4) + '.png'
        var fd = { name: blob.name, percentage: 0, raw: blob, size: blob.size, status: 'ready' }
        this.updateAlianOss().then(response => {
          this.outMsg = '开始上传中'
          if (response.code === 0) {
            var fileName = dayjs(new Date()).format('YYYYMMDDHHmmss') + Math.random().toString().substr(2, 3) + fd.raw.name // 上传阿里保存 文件名=日期+3位随机码+原有文件名
            let keyValue = response.data.dir + fileName
            this.postAliImage(response.data, fd, fileName, keyValue).then(res => {
              if (res.status === 200) {
                var imageUrl = res.config.url + '/' + keyValue
                this.outMsg = '完成上传，请上传相关单据'
                self.$emit('WebCamPhoto', { imageUrl: imageUrl })
                self.videoObj.play()
              }
            })
          }
        })
      }
    },
    // 阿里OSS签名
    updateAlianOss () {
      return uploadAli()
    },
    // 上传图片到阿里OSS
    postAliImage (data, file, fileName, keyValue) {
      var ossData = new FormData()
      ossData.append('name', fileName)
      ossData.append('key', keyValue)
      ossData.append('policy', data.policy)
      ossData.append('OSSAccessKeyId', data.accessid)
      ossData.append('success_action_status', 200)
      ossData.append('signature', data.signature)
      ossData.append('callback', data.callback)
      ossData.append('file', file.raw, file.raw.name)
      return axios.post(data.host, ossData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    }
  },
  watch: {
  },
  created () {
  },
  mounted () {
    this.changeScam(this.constraints)
    this.fetchCamList()
  },
  destroyed () {
  }
}
</script>

<style lang="scss" scoped>
#app {
  width: 48%;
}
.option-view {
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.webCamDiv {
  width: 100%;
}
.loading-indicator {
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
}
.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, .8);

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  font-size: 120px;
  color: #42d511;
}
.image-div {
  position: absolute;
  width: 800px;
  height: 600px;
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  flex-flow: column nowrap;
}
.outTime-span {
  color: #FFFFFF;
  font-size: 200px;
  font-style:oblique;
}
.code-box {
  background: linear-gradient(#00ff00, #00ff00) left top,
  linear-gradient(#00ff00, #00ff00) left top,
  linear-gradient(#00ff00, #00ff00) right top,
  linear-gradient(#00ff00, #00ff00) right top,
  linear-gradient(#00ff00, #00ff00) right bottom,
  linear-gradient(#00ff00, #00ff00) right bottom,
  linear-gradient(#00ff00, #00ff00) left bottom,
  linear-gradient(#00ff00, #00ff00) left bottom;
  margin: 200px 0px 0px 300px;
  background-repeat: no-repeat;
  background-size: 2px 20px, 20px 2px;
  border: 1px #42d511 solid;
  height: 150px;
  width: 150px;
}
.error-span {
  color: #cd0060;
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-qrcode/index.vue"
}
</vue-filename-injector>
